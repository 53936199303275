import home from '../../../../../assets/icon/home.svg';
import { ReactComponent as HomeIcon } from './home.svg';

const DefaultInflation = 6;
const DefaultROI = 12;

export const PortfolioType = [
    {
        id: 1,
        value: 'Bank FDs',
    },
    {
        id: 2,
        value: 'Mutual Funds',
    },
    {
        id: 3,
        value: 'Stocks',
    },
    {
        id: 4,
        value: 'PPF',
    },
    {
        id: 5,
        value: 'Others',
    },
];
export const AgeGroupArray = [
    {
        id: 1,
        value: 'Below 25',
    },
    {
        id: 2,
        value: '25 - 40',
    },
    {
        id: 3,
        value: '41 - 50',
    },
    {
        id: 4,
        value: 'Above 50',
    },
];
export const Occupation = [
    {
        id: 1,
        value: 'Salaried',
    },
    // {
    //     id: 2,
    //     value: 'Business',
    // },
    {
        id: 3,
        value: 'Self Employed',
    },
    {
        id: 4,
        value: 'Others',
    },
];

export const ReactInvstDown = [
    {
        id: 1,
        value: 'Stay invested and observe',
    },
    {
        id: 2,
        value: 'Take out all investments to protect further downside',
    },
    {
        id: 3,
        value: 'Invest more to average out the cost',
    },
    {
        id: 4,
        value: 'Take professional advice',
    },
];

export const ExpensesType = [
    {
        id: 1,
        value: 'EMIs',
    },
    {
        id: 2,
        value: 'Utilities',
    },
    {
        id: 3,
        value: 'Family',
    },
    {
        id: 4,
        value: 'Other',
    },
];
export const ExpensesSubType = [
    {
        id: 1,
        parent: 1,
        value: 'Home Loan',
    },
    {
        id: 2,
        parent: 1,
        value: 'Education Loan',
    },
    {
        id: 3,
        parent: 1,
        value: 'Personal Loan',
    },
    {
        id: 4,
        parent: 1,
        value: 'Car Loan',
    },
    {
        id: 5,
        parent: 1,
        value: 'Others',
    },
    {
        id: 6,
        parent: 2,
        value: 'House Rent ',
    },
    {
        id: 7,
        parent: 2,
        value: 'Groceries ',
    },
    {
        id: 8,
        parent: 2,
        value: 'Medicines ',
    },
    {
        id: 9,
        parent: 2,
        value: 'Internet',
    },
    {
        id: 10,
        parent: 2,
        value: 'Mobile Bill',
    },
    {
        id: 11,
        parent: 2,
        value: 'TV Cable',
    },
    {
        id: 12,
        parent: 2,
        value: 'Electricity',
    },
    {
        id: 13,
        parent: 2,
        value: 'Fuel',
    },
    {
        id: 14,
        parent: 2,
        value: 'Others',
    },
    {
        id: 15,
        parent: 3,
        value: 'Kids Eduction',
    },
    {
        id: 16,
        parent: 3,
        value: 'Shopping, Dineout etc.',
    },
    {
        id: 17,
        parent: 3,
        value: 'Gym, Swimming etc.',
    },
    {
        id: 21,
        parent: 3,
        value: 'Others',
    },
    {
        id: 18,
        parent: 4,
        value: 'Driver',
    },
    {
        id: 19,
        parent: 4,
        value: 'Maid',
    },
    {
        id: 20,
        parent: 4,
        value: 'Others',
    },
];
export const perMonthSaveArr = [
    {
        id: 1,
        value: 'Less than 15%',
    },
    {
        id: 2,
        value: '15 - 30%',
    },
    {
        id: 3,
        value: '30 - 45%',
    },
    {
        id: 4,
        value: 'More than 45%',
    },
    {
        id: 5,
        value: 'I am not sure',
    },
];
export function numberWithCommas(x) {
    let ReturnValue = '';
    if (x && x !== '' && x !== null) {
        ReturnValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // ReturnValue = 'saf';
    } else {
        ReturnValue = '';
    }
    return ReturnValue;
}

function GetPMT(ir, np, pv, fv) {
    // https://stackoverflow.com/questions/5294074/pmt-function-in-javascript
    let pmt = 0;
    let temp = 0;
    let pvif = 0;
    let typeHere = 0;

    typeHere = 0;

    if (ir === 0) return -(pv + fv) / np;

    pvif = (1 + ir) ** np;
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (typeHere === 1) {
        temp = 1 + ir;
        pmt /= temp;
    }
    pmt = pmt.toFixed(0);
    // pmt - number
    return pmt * -1;
}

function calculateCompoundValue(principal, rate, time) {
    /* Calculate compound interest */
    if (principal !== '') {
        const temp = 1 + Number(rate) / 100;
        const futureValue = Number(principal) * temp ** Number(time);
        console.log(`futureValue: ${futureValue}`);
        return futureValue;
    }
    return 0;
}

export function calculateSip(prstval, prstvalIn, Target, Inflation, ROI) {
    let PresentValueIn = 0;
    switch (prstvalIn) {
        case 1:
        case '1':
            PresentValueIn = 10000000;
            break;
        case 2:
        case '2':
            PresentValueIn = 100000;
            break;
        case 3:
        case '3':
            PresentValueIn = 1000;
            break;
        default:
            PresentValueIn = 1;
            break;
    }
    PresentValueIn = 1;
    const PresentValue = Number(prstval) * Number(PresentValueIn);
    const FutureValue = calculateCompoundValue(PresentValue, Inflation, Number(Target));
    // ratePerPeriod, numberOfPayments, presentValue, futureValue, type
    const SipFutureValue = GetPMT(ROI / 100 / 12, Number(Target) * 12, 0, FutureValue, 0);
    console.log(SipFutureValue);
    return SipFutureValue;
}
export const DefaultGolesList = [
    {
        index: 1,
        name: 'Home',
        img: <img src={home} alt="home" />,
        icon: 'fa-home',
        selected: 0,
        filled: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
    {
        index: 2,
        name: 'Car/Bike',
        img: <img src="/icon/home.svg" alt="home" />,
        icon: 'fa-car-side',
        selected: 0,
        filled: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
    {
        index: 3,
        name: 'Education',
        filled: 0,
        img: <HomeIcon />,
        icon: 'fa-graduation-cap',
        selected: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
    {
        index: 4,
        name: 'Trip',
        img: 'trip.svg',
        filled: 0,
        icon: 'fa-suitcase-rolling',
        selected: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
    {
        index: 5,
        name: 'Marriage',
        img: 'marriage.svg',
        filled: 0,
        icon: 'fa-rings-wedding',
        selected: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
    // {
    //     index: 6,
    //     name: 'Retirement',
    //     filled: 0,
    //     icon: 'fa-umbrella-beach',
    //     selected: 0,
    //     presentValue: '',
    //     ValueOn: 2,
    //     target: '',
    //     Inflation: DefaultInflation,
    //     ROI: DefaultROI,
    // },
    {
        index: 7,
        name: 'Other',
        filled: 0,
        img: 'otherGoal.svg',
        icon: 'fa-bullseye-arrow',
        selected: 0,
        presentValue: '',
        ValueOn: 2,
        target: '',
        Inflation: DefaultInflation,
        ROI: DefaultROI,
    },
];
const PageFor = 'Users';
export default PageFor;
