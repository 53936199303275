import React, {  } from 'react';
import logo from "../../assets/images/mutualfundimg/Personal finance-rafiki 1.svg";
import rightarrow from "../../assets/images/mutualfundimg/Icon.svg";
import "./css/herosection.css"

function HeroSection(){
    

    return <div> 
        <div className='hs-main'>
            <div className='hs-container'>
            <h1 id='mutualfund'>
                Mutual Funds Investment
            </h1>
            {/* <h1 id='investment'>
                Investment
            </h1> */}
            </div>
            <div className='secondcontainer'>
                <p>
                Your key to financial freedom is here. Harness the power of diversified portfolios, expert guidance, and simplified investing. Invest smarter, and grow faster with My Money Panda.
                </p>
            </div>
            <div className='buttonimgcontainer'>
                <div className='rightbuttondiv'>
                    <a href="/review" target="blank">
                    Get Started
                    <div className='rightarrowdiv'>
                        <img src={rightarrow} alt="rightarrow"/>
                    </div>
                    </a>
                    
                </div>
                <div className='logoimgdiv'>
                    <img src={logo} alt="logo"/>
                </div>
            </div>
        </div>
    </div>
}

export default HeroSection;