import React, { useState } from 'react';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Drawer from '../../../Mobile/DrawerAdmin';
import Header from '../../../Helper/Header';
import Footer from '../../../Helper/Footer';

import { IMGBASEAPIURL, BASEURL, BASEAPIURL, setCookie } from '../../../../config';
import '../../administrator.css';

function AdministratorLogin() {
    // const history = useHistory();
    const [drawer, drawerAction] = useToggle(false);
    const [lfVisibility, setlfVisibility] = useState('block');
    const [fpVisibility, setfpVisibility] = useState('none');
    const [msgcolor, setMsgcolor] = useState('red');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordType, setPasswordType] = useState('password');
    const [message, setMessage] = useState('');
    const [Remembeme, setRemembeme] = useState(1);
    // console.log(getCookie('token'));
    const temp1 = 9999 + Math.random() * (9999999999 - 9999);
    const d = new Date();
    const temp = d.getMilliseconds() + temp1;
    const handleSubmit = async (e) => {
        e.preventDefault();
        let fordays = 1;
        try {
            const res = await fetch(`${BASEAPIURL}/admin/account/login?v=${temp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: Username,
                    password: Password,
                }),
            });
            console.log(res,'res');
            const resJson = await res.json();
            console.log(resJson, 'resjson')
            if (res.status === 200) {
                // setUsername('')
                // setPassword('')
                console.log(resJson);
                setMsgcolor('red');
                if (resJson.status === 1) {
                    setMsgcolor('green');
                    // sd
                    if (Remembeme === 1) {
                        fordays = 30;
                    } else {
                        fordays = 1;
                    }
                    console.log(fordays);
                    setCookie('rytoken', resJson.jwttoken, fordays);
                    localStorage.setItem('token', resJson.token);
                    localStorage.setItem('username', resJson.username);
                    localStorage.setItem('usertype', 'admin');
                    // localStorage.setItem('rytoken', resJson.jwttoken);
                    setTimeout(() => {
                        // history.push('/admin-dashboard');
                        window.location.href = `${BASEURL}admin-dashboard`;
                    }, 300);
                    console.log(BASEURL);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Administrator Login</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-7">
                                        <form
                                            className="m-4 p-4 fpform"
                                            style={{ display: fpVisibility }}
                                        >
                                            <div className="form-group mt-4">
                                                <label>
                                                    <b>Email ID</b>
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email ID"
                                                    className="form-control"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ width: '100%' }}
                                            >
                                                Reset Password
                                            </button>
                                            <div className="text-right mt-2">
                                                <a
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setlfVisibility('block');
                                                        setfpVisibility('none');
                                                    }}
                                                >
                                                    <b>Back to Login</b>
                                                </a>
                                            </div>
                                        </form>
                                        <form
                                            className="m-4 p-4"
                                            onSubmit={handleSubmit}
                                            style={{ display: lfVisibility }}
                                        >
                                            <div className="form-group">
                                                <label>
                                                    <b>Email ID</b>
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email ID"
                                                    className="form-control"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={Username}
                                                />
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <label>
                                                    <b>Password</b>
                                                </label>
                                                <input
                                                    type={PasswordType}
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    className="form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={Password}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        bottom: '10%',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setPasswordType('text')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'text'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setPasswordType('password')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'password'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="text-right mt-2 d-none">
                                                <input
                                                    type="checkbox"
                                                    value="1"
                                                    name="remembeme"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setRemembeme(1);
                                                        } else {
                                                            setRemembeme(0);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="message mb-3"
                                                style={{ color: msgcolor }}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-docsta-instructor"
                                            >
                                                Login
                                            </button>
                                            <div className="text-right mt-2">
                                                <a
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setlfVisibility('none');
                                                        setfpVisibility('block');
                                                    }}
                                                >
                                                    <b>Forgot Password?</b>
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-5 p-4 text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}admin/login.jpg`}
                                            alt="Docsta Learn Admin Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AdministratorLogin;
