import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Parser from 'html-react-parser';
// import axios from 'axios';
// import { useAuth0 } from '@auth0/auth0-react';
// import Parser from 'html-react-parser';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
// import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEAPIURL, BASEURL } from '../../config';

const Token = localStorage.getItem('token');
const jwttoken = localStorage.getItem('jwttoken');
// const LSName = localStorage.getItem('fname');
const LSEmail = localStorage.getItem('email');
// const LSMobile = localStorage.getItem('mobile');

function Profile() {
    const [user, setUserdata] = useState([]);
    // const [uploadImage, setUploadImage] = useState(null);
    const [drawer, drawerAction] = useToggle(false);
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [fname, setFname] = useState('');
    const [mobile, setMobile] = useState('');
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState('');
    const [married, setMarried] = useState('');

    useEffect(() => {
        if (Token && Token !== 0 && Token !== '0') {
            console.log(Token);
        } else if (jwttoken && jwttoken !== 0 && jwttoken !== '0') {
            console.log(jwttoken);
        } else {
            window.location.href = `${BASEURL}login?action=login&message=Please Login to Continue`;
        }
        const fetchUser = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/user/getProfile`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        token: Token,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setUserdata(resJson.data);
                    setMobile(resJson.mobile);
                    setFname(resJson.fname);
                    setDob(resJson.dob);
                    setGender(resJson.gender);
                    setMarried(resJson.married);
                    console.log(resJson.data);
                } else {
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-danger">${resJson.message}</div>`));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchUser();
    }, []);

    function updateUser(e) {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        console.log(dob, gender, fname, mobile, married, Token);
        const data = { dob, gender, fname, mobile, married, token: Token };
        fetch(`https://services.mymoneypanda.in/api/user/updateProfile`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            result.json().then((resp) => {
                setMessage(Parser(`<div class="alert alert-success"}>${resp.message}</div>`));
                console.log(resp);
            });
        });
        // async function uploadFile(e, file, fileName) {
        //     e.preventDefault();
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     formData.append('fileName', fileName);
        //     const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        //     try {
        //         const res = await axios.post(url, formData);
        //         console.log(res);
        //         HandleForm('image', res.data.filename);
        //     } catch (ex) {
        //         console.log(ex);
        //     }
        // }
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile-block">
                                <div className="leftBox">
                                    {/* {uploadImage && (
                                        <div className="d-flex">
                                            <img
                                                alt="not found"
                                                width="200px"
                                            // position="relative"
                                            // margin="auto"
                                            // src={uploadImage.userImg1}
                                            />
                                        </div>
                                    )} */}
                                    <h5>{user.fname}</h5>
                                    <a href={`mailto:${LSEmail}`}>
                                        <i className="far fa-envelope" />
                                        {LSEmail}
                                    </a>
                                    <hr />
                                    <div className="navBox">
                                        <NavLink exact to="/profile">
                                            <i className="far fa-user" />
                                            Basic Details
                                        </NavLink>
                                        {/* <NavLink exact to="/ContactDetail">
                                            <i className="far fa-address-book" />
                                            &nbsp;Contact Details
                                        </NavLink>
                                        <NavLink exact to="/BankDetail">
                                            <i className="far fa-landmark"></i>
                                            Bank Details
                                        </NavLink>
                                        <NavLink exact to="/nominee">
                                            <i className="far fa-user-plus" />
                                            Nominee Details
                                        </NavLink> */}
                                        <NavLink exact to="/change-password">
                                            <i className="far fa-lock" />
                                            Change Password
                                        </NavLink>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                localStorage.removeItem('jwttoken');
                                                window.location.href = BASEURL;
                                            }}
                                        >
                                            <div className="iconBox">
                                                <i className="fal fa-sign-out" />
                                            </div>
                                            <div>Logout</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <hr className="d-lg-none d-block mt-50 mb-50" />
                                <div className="rightBox">
                                    <h6>Basic Details</h6>
                                    <form>
                                        <div className="form-area mb-20">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <label>Full name</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control"
                                                            placeholder="full name"
                                                            defaultValue={user.fname}
                                                            onChange={(e) => {
                                                                setFname(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type="date"
                                                            name="dob"
                                                            defaultValue={user.dob}
                                                            onChange={(e) => {
                                                                setDob(e.target.value);
                                                            }}
                                                            placeholder="Enter Date of Birth"
                                                        />
                                                        <label>Date of Birth</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <input
                                                        type="text"
                                                        name="gender"
                                                        placeholder="Enter Gender"
                                                    /> */}
                                            {/* <label>Gender :&nbsp;</label> */}
                                            {/* <input
                                                        name="gender"
                                                        type="text"
                                                        Value={user.gender}
                                                        onChange={(e) => {
                                                            setGender(e.target.value);
                                                        }}
                                                    /> */}
                                            {/* <select
                                                        onBlur={(e) => {
                                                            setGender(e.target.value);
                                                        }}
                                                    >
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="other">Other</option>
                                                    </select> */}

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <select
                                                            className="form-control form-box"
                                                            defaultValue={user.married}
                                                            onBlur={(e) => {
                                                                setMarried(e.target.value);
                                                            }}
                                                            style={{ height: '65px' }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="married">Married</option>
                                                            <option value="Un-Married">
                                                                Un-Married
                                                            </option>
                                                            <option value="Divorced">
                                                                Divorced
                                                            </option>
                                                            <option value="Widow">Widow</option>
                                                        </select>
                                                        <label> Martial Status </label>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {/* <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            name="married"
                                                            defaultValue={user.married}
                                                            onChange={(e) => {
                                                                setMarried(e.target.value);
                                                            }}
                                                            placeholder="Enter Your Martial Status"
                                                        />
                                                        <label> Martial Status </label>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <select
                                                            className="form-control"
                                                            defaultValue={user.gender}
                                                            required
                                                            onBlur={(e) => {
                                                                setGender(e.target.value);
                                                            }}
                                                            style={{ height: '65px' }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                        <label> Gender </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className=" form-box d-flex">
                                                            <label>Gender :&nbsp;</label>
                                                            <select
                                                                className="form-control"
                                                                defaultValue={user.gender}
                                                                required
                                                                onBlur={(e) => {
                                                                    setGender(e.target.value);
                                                                }}
                                                                aria-label="Default select example"
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">
                                                                    Female
                                                                </option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* </div> */}
                                        </div>
                                        <h6>Contact Details</h6>
                                        <div className="form-area">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter Your Email Id"
                                                            Value={user.email}
                                                        />
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type="number"
                                                            name="mobile"
                                                            placeholder="Enter Your Mobile No."
                                                            Value={user.mobile}
                                                            onChange={(e) => {
                                                                setMobile(e.target.value);
                                                            }}
                                                        />
                                                        <label>Mobile</label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <b>Image Thumbnail</b>
                                                    <br />
                                                    <img src={`${UPLOADNODEURL}${Category.image}`} alt={Category.title} />
                                                    <input
                                                        accept="image/*"
                                                        required={forfreetipautoid === 'NEW' ? true : false}
                                                        type="file"
                                                        name="image"
                                                        onChange={(e) => {
                                                            uploadFile(
                                                                e,
                                                                e.target.files[0],
                                                                e.target.files[0].name
                                                            );
                                                        }}
                                                    />
                                                </div> */}
                                                <div style={{ clear: 'both', margin: '30px' }}>
                                                    <div style={styleMSG}>{message}</div>
                                                </div>
                                                <div className="col-lg-12 mt-20 text-lg-left text-center">
                                                    <input
                                                        type="submit"
                                                        name="submit"
                                                        value="Save"
                                                        onClick={updateUser}
                                                    />
                                                    {/* <button onClick={updateUser}  type="submit">
                                                        Save
                                                    </button> */}
                                                    <input
                                                        type="submit"
                                                        name="submit"
                                                        value="Cancel"
                                                        className="cancel"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <BackToTop />
        </>
    );
}

export default Profile;
