import React, {useState, useEffect} from 'react';
import './css/fourherosection.css'

function Card({ data }){

    const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 480);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktopView(window.innerWidth > 480);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const borderStyle = isDesktopView ? {
        borderRight: (data.id === 1 || data.id === 2 || data.id === 4 || data.id === 5) ? '1px solid rgba(228, 228, 231, 1)' : 'none',
        borderBottom: (data.id === 1 || data.id === 2 || data.id === 3) ? '1px solid rgba(228, 228, 231, 1)' : 'none'
    } : {
        borderRight: (data.id === 1 || data.id === 3 || data.id === 5) ? '1px solid rgba(228, 228, 231, 1)' : 'none',
        borderBottom: (data.id === 1 || data.id === 2 || data.id === 3 || data.id === 4) ? '1px solid rgba(228, 228, 231, 1)' : 'none'
    };


    return <>
        <div className="fhs-card-main" style={borderStyle}>
            <div >
                <img src={data.img} alt={data.title}/>
            </div>
            <p className="fhs-card-subtitle">{data.title}</p>
            <div className="fhs-card-description"> 
                <p>{data.description}</p>
            </div>
        </div>
    </>
}

export default Card;
