/* eslint-disable no-restricted-properties */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import CalculatorComponent from "./Calculator";
import SIPContent from './SIPContent';
import style from "./css/calculator.module.css";
import FAQComponent from "../MutualFund/LumpsumFAQ";
import { SIP_FAQ } from "../MutualFund/FAQ";

const SIPCalculatorPage = ({
  handleSPIClick,
  selectedType,
  changeBack,
}) => {
  const [graphData, setGraphData] = useState({
    years: [2024, 2025, 2026, 2027, 2028],
    investedGraph: [120000, 240000, 360000, 480000, 600000],
    gainGraph: [8093, 32432, 75076, 138348, 224864],
  });

  const [SIPInput, setSIPInput] = useState({
    investment: 10000,
    investmentRange: {
      min: 500,
      max: 1000000,
    },
    investmentDuration: 5,
    frequency: "monthly",
    durationRange: {
      min: 2,
      max: 30,
    },
    roi: 12,
    roiRange: {
      min: 5,
      max: 30,
    },
  });

  const [SIPOutput, setSIPOutput] = useState({
    investedAmount: 100000,
    estimateReturn: 147596,
    totalValue: 247596,
  });

  useEffect(() => {
    calculateSIPValues();
    calculateGraphValue(SIPInput.investmentDuration);

  }, [SIPInput]);

  const generateYears = (value) => {
    const MIN_YEAR = 2001;
    if (value < 2 || value > 30) {
      console.error('Value must be between 2 and 30');
      return [];
    }
    const currentYear = new Date().getFullYear() % 100;
    const years = [MIN_YEAR + currentYear];
    for (let i = 1; i < value; i++) {
      if (value > years.length) {
        years.push(years[years.length - 1] + 1);
      } else if (value < years.length) {
        years.pop();
      }
    }
    return years;
  };

  const calculateGraphGain = (years, investment, roi, duration) => {
    const gains = [];
    const monthlyRoi = roi / 100 / 12;

    for (let i = 0; i < years.length; i++) {
      const j = i + 1
      const totalMonths = (i + 1) * 12;
      const futureValue = (investment * ((1 + monthlyRoi) ** totalMonths - 1) / monthlyRoi * (1 + monthlyRoi));

      // Calculate gain for the current year
      const gain = Math.floor(futureValue - investment * totalMonths);

      // Add gain for the current year to the array
      gains.push(gain);
    }
    return gains;
  };

  const calculateGraphInvestment = (years, investment, duration) => {
    const investments = [];

    for (let i = 0; i < years.length; i++) {
      const totalInvestment = investment * (i + 1) * 12;
      investments.push(totalInvestment);
    }

    return investments;
  };

  const calculateGraphValue = (newValue) => {
    const { investment, roi } = SIPInput;
    const years = generateYears(newValue);
    const graphGain = calculateGraphGain(years, investment, roi, newValue);
    const graphInvestment = calculateGraphInvestment(years, investment, newValue);

    setGraphData((prev) => ({
      ...prev,
      years: years,
      gainGraph: graphGain,
      investedGraph: graphInvestment,
    }));
  };

  const calculateSIPValues = () => {
    const { investment, investmentDuration, roi, frequency } = SIPInput;
    const investedAmount = calculateInvestedAmount(
      investment,
      investmentDuration,
      frequency
    );
    const totalValue = calculateTotalValue(investment, investmentDuration, roi);
    const estimateReturn = calculateEstimateReturn(totalValue, investedAmount);

    setSIPOutput((prevSIPOutput) => ({
      ...prevSIPOutput,
      investedAmount: investedAmount,
      estimateReturn: estimateReturn,
      totalValue: totalValue,
    }));
  };

  const calculateInvestedAmount = (investment, duration, frequency) => {
    if (frequency !== "monthly") {
      return investment * duration;
    }
    return investment * duration * 12;
  };

  const calculateEstimateReturn = (totalValue, investedAmount) =>
    totalValue - investedAmount;

  const calculateTotalValue = (investment, investmentDuration, roi) => {
    const i = roi / 100 / 12;
    return (
      (((i + 1) ** (investmentDuration * 12) - 1) / i) * (1 + i) * investment
    );
  };

  const handleRoiRange = (event, newValue) => {
    setSIPInput((prevSIPValue) => ({
      ...prevSIPValue,
      roi: newValue,
    }));
  };

  const handleInvestmentEdit = (event, newValue) => {


    setSIPInput((prevSIPValue) => ({
      ...prevSIPValue,
      investment: event.target.value,
    }));
  };
  // console.log("===>167", SIPInput)


  const handleInvestmentRange = (event, newValue) => {
    setSIPInput((prevSIPValue) => ({
      ...prevSIPValue,
      investment: newValue,
    }));
  };

  const handleDurationEdit = (event, newValue) => {
    const maximumYear = 30;
    const inputValue = event.target.value;
    setSIPInput((prevSIPValue) => ({
      ...prevSIPValue,
      investmentDuration: inputValue > maximumYear ? maximumYear : inputValue,
    }));
    calculateGraphValue(newValue);
  };

  const handleDurationRange = (event, newValue) => {
    setSIPInput((prevSIPValue) => ({
      ...prevSIPValue,
      investmentDuration: newValue,
    }));
    calculateSIPValues();
    calculateGraphValue(newValue);
  };

  return (
    <div className={style.SIP_calculator}>
      <p className={style.sip_calculator_heading}>SIP Calculator</p>
      <p>
        Use MyMoneyPanda SIP Calculator to plan your investments wisely.
        Calculate the potential returns and growth of your Systematic Investment
        Plan (SIP) over time.
      </p>
      <CalculatorComponent
        onButtonClick={handleSPIClick}
        type={selectedType}
        values={SIPInput}
        handleRoiRange={handleRoiRange}
        handleInvestmentEdit={handleInvestmentEdit}
        handleInvestmentRange={handleInvestmentRange}
        handleDurationEdit={handleDurationEdit}
        handleDurationRange={handleDurationRange}
        changeBack={changeBack}
        outputResult={SIPOutput}
        graphData={graphData}
      />
      <SIPContent />
      <FAQComponent questions={SIP_FAQ} />
    </div>
  );
};

export default SIPCalculatorPage;
