import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/Logo1.png';
// import logo from '../../assets/images/Logo1.png';
import { BASEAPIURL } from '../../config';

function Footer({ className }) {
    // for the Subscipt for,
    const [styleMSG, setstyleMSG] = useState({ color: '#cc0000', padding: '5px', width: '100%' });
    const [message, setMessage] = useState('');
    const [user, SetUser] = useState({
        email: '',
        UserIpAddress: localStorage.getItem('UserIpAddress'),
    });
    const HandleForm = (e) => {
        SetUser({ ...user, [e.target.name]: e.target.value });
    };
    function IfRequestDone() {
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }
    const SubmitData = async (e) => {
        e.preventDefault();
        setMessage('Sending Request.');
        setstyleMSG({ color: '#cc0000', padding: '5px', width: '100%' });
        try {
          const res = await fetch(`${BASEAPIURL}/admin/subscriber`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user),
          })
          const resJson = await res.json()
          IfRequestDone();
          if (res.status === 200) {
            if (resJson.status === 1) {
                SetUser({
                    email: '',
                    UserIpAddress: localStorage.getItem('UserIpAddress'),
                });
                setstyleMSG({ color: 'green', padding: '5px', width: '100%' });
            }
            setMessage(resJson.message)
          } else {
            setMessage(resJson.message)
          }
        } catch (err) {
            console.log(err,"err______________________________err")
            setMessage(err)
            IfRequestDone();
        }
      };
    // for the Subscipt for,

    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-subscribe-widget">
                                <span>Subscribe</span>
                                <div className="subscribe-box">
                                    <div className="text-box">
                                        <h3>Latest News & Update</h3>
                                        <p>Subscribe to stay up-to-date with industry news!</p>
                                    </div>
                                    <form
                                        method="POST"
                                        className="input-box"
                                        onSubmit={(e)=>{
                                            SubmitData(e)
                                        }}
                                    >
                                        <input
                                            type="email"
                                            required
                                            placeholder="Enter Your Email"
                                            value={user.email}
                                            name="email"
                                            onChange={HandleForm}
                                        />
                                        <div className="hideEmpty" style={styleMSG}>{message}</div>
                                        <button
                                            type="submit"
                                            style={{
                                                display: message === '' ? '' : 'none',
                                            }}
                                        >
                                            Subscribe
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="footer-about-widget">
                                <div
                                    className="logo"
                                    style={{
                                        display: 'flex',
                                        margin: 'auto',
                                        maxWidth: '140px',
                                        }}>
                                    <NavLink to="/">
                                        <img src={logo} alt="" />
                                    </NavLink>
                                </div>
                                <p>
                                    MyMoneyPanda<sup>TM</sup> is your one-stop money management guide. Whether
                                    you want to build your finance profile or learn more about the
                                    industry, we have experts.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-navigation">
                                <h4 className="title">Quick Links</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/about-us">Our story</NavLink>
                                        {/* <a href="#">Our story</a> */}
                                    </li>
                                    <li>
                                        {/* <NavLink to="/review-investment"> */}
                                        <NavLink to="/review">
                                            Build your financial plan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/blog">Blogs</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq">FAQs</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/information/term-and-conditions">
                                            Terms & Conditions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/information/privacy-policy">
                                            Privacy Policy
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="footer-widget-info">
                                <span className="supportBox" style={{ color: 'white', marginBottom: '20px' }}>
                                    <b>CONTACT US</b>
                                </span>
                                <div className="supportBox">
                                    <div>
                                        <i className="far text-white fa-phone mr-2" />
                                        <a href="tel:+919920112179" className="d-inline-block  mr-2">    
                                            +91 99201 12179, 
                                        </a>
                                        <a href="tel:+918979523872" className="d-inline-block">    
                                            +91 89795 23872 
                                        </a>
                                    </div>
                                    <div>
                                        <i className="far text-white fa-envelope mr-2" />
                                        <a href="mailto:hello@mymoneypanda.in" className="d-inline-block  mr-2">    
                                            hello@mymoneypanda.in
                                        </a>
                                    </div>
                                </div>
                                <div className="social"  style={{ marginTop: '10px' }}>
                                    <h4 className="title">Follow us on</h4>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/profile.php?id=100094190271471&mibextid=D4KYlr"
                                                target="_BLANK"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/my-money-panda/"
                                                target="_BLANK"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        
                                              <li>
                                            <a  rel="noreferrer" href="https://instagram.com/mymoneypanda?igshid=MzRlODBiNWFlZA==" target='_blank'>
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                           <li>
                                        <a  rel="noreferrer" href="https://www.youtube.com/@mymoneypanda" target='_blank'>
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright">
                                <div className="copyright-text">
                                    <p className="d-none">
                                        Mutual fund investments are subject to market risks. Please
                                        read the scheme information and other related documents
                                        before investing. Past performance is not indicative of
                                        future returns.
                                    </p>
                                    <p>
                                        MyMoneyPanda<sup>TM</sup> is a brand registered with Indniveza Distributors
                                        Private Limited.
                                        <br />
                                        Mutual Fund distribution services are offered through
                                        Indniveza Distributors Private Limited with AMFI
                                        Registration No : ARN-260681.
                                        <br />
                                        Mutual fund investments are subject
                                        to market risks. Please read the scheme information and other
                                        related documents before investing. Past performance is not
                                        indicative of future returns
                                    </p>
                                    <p className="copyr">
                                        MyMoneyPanda<sup>TM</sup> &copy; 2023. All Rights Reserved.
                                    </p>
                                    <p className="mt-10 d-none">
                                        Design & Developed by
                                        <a
                                            href="https://www.sjain.io/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src="https://sjain.io/logo/sjain-white.svg"
                                                style={{ width: '35px', marginLeft: '5px' }}
                                                alt="Sjain Ventures"
                                            />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;


