import React from 'react';
import headphone from "../../assets/images/mutualfundimg/headphones-3 1.svg";
import chartbar from "../../assets/images/mutualfundimg/chart-bar-33 1.svg";
import board from "../../assets/images/mutualfundimg/board-2 1.svg";
import archive from "../../assets/images/mutualfundimg/archive-content 1.svg";
import folder from "../../assets/images/mutualfundimg/folder-favorite 1.svg";
import chart from "../../assets/images/mutualfundimg/chart-3 1.svg";
import Card from './Card';
import './css/fourherosection.css'


const data = [
    {
        id: 1,
        img: headphone,
        title: 'Diversification',
        description: 'By investing in mutual funds, you spread your investment across a wide range of assets, reducing the risk associated with investing in a single stock or bond.'
    },
    {
        id: 2,
        img: chartbar,
        title: 'Affordability',
        description: 'Investment in mutual funds allows you to start with as low as a few hundred rupees, making it an affordable entry to the market for most investors.'
    },
    {
        id: 3,
        img: board,
        title: 'Liquidity',
        description: 'One of the key advantages of mutual funds is the ability to withdraw your investment at any time. This offers a high degree of liquidity compared to other investment options.'
    },
    {
        id: 4,
        img: archive,
        title: 'Transparency',
        description: 'Mutual funds are highly regulated and transparent. You can easily track the performance of your investment and the underlying assets in which your money is invested.'
    }
    , {
        id: 5,
        img: folder,
        title: 'Professional Management',
        description: 'Your mutual funds investment is managed by skilled professionals. They take care of market research, asset selection, and timing the market, allowing you to sit back and watch your money grow.'
    },
    {
        id: 6,
        img: chart,
        title: 'Results',
        description: 'Mutual funds have historically delivered higher returns than other investment options. With the right fund, you can expect to earn higher returns than the market average.'
    }
];

function FourHeroSection(){
    return <>
        <h2 id='fhs-content'> Advantages of Investing in Mutual Funds</h2>
        <div className="fhs-main">
            <div className="fhs-container">
                {data.map((item) => (
                    <Card key={item.id} data={item}/>
                ))}
            </div>
        </div>
    </>
}

export default FourHeroSection;


// function FourHeroSection(){
//     return <div style={{
//         display: 'grid',
//         gridTemplateColumns: 'repeat(3, 1fr)',
//         gap: '1rem',
//         alignItems: 'start',
//         marginLeft: '5rem',
//         border: '1px solid red'
//     }}>
//         {data.map((item) => (
//             <Card key={item.id} data={item}/>
//         ))}
//     </div>
// }



// function FourHeroSection(){
//     return <div style={{
//         marginLeft: '5rem',
//     }}>
//         <div style={{
//         display: 'grid',
//         gridTemplateColumns: 'repeat(3, 1fr)',
//         gap: '1rem'
//     }}>
//         {data.map((item) => (
//             <Card key={item.id} data={item}/>
//         ))}
//     </div>
//     </div>
// }



