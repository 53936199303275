import React from "react";
import bell from "../../assets/images/mutualfundimg/Group 35899.svg";
import griddesign from "../../assets/images/mutualfundimg/Grid design-pana 1.svg";
import './css/fiveherosection.css'

function FiveHeroSection(){


    const featureData = [
        {
          id: 1,
          title: 'Personalized Approach',
          description: 'We tailor mutual funds investment strategies to fit your financial goals.',
        },
        {
          id: 2,
          title: 'Simplified Investing',
          description: 'We break down complex financial jargon, making wealth management easier for you.',
        },
        {
          id: 3,
          title: 'Expert Guidance',
          description: 'Our team of financial experts is committed to helping you navigate through your investment journey.',
        },
        {
          id: 4,
          title: 'Flexible Plans',
          description: 'Whether you\'re just starting out or a seasoned investor, we offer plans suitable for all.',
        },
        {
          id: 5,
          title: 'Lifelong Learning',
          description: 'From basic concepts to industry insights, learn everything about finance with us.',
        },
      ];

      return <>
        <div className="fihs-main-container">
        <div className="fihs-main">
           <div className="fihs-container">
              <div className="fihs-title-container">
                <h2 className="fihs-title">Why choose MyMoneyPanda?</h2>
              </div>
              <div className="fihs-image-container">
                <img src={griddesign} alt="griddesign"/>
              </div>
              <div className="fihs-main-feature-container">
              {featureData.map((item) => (
                 <div className="fihs-main-feature" key={item.id}>
                    <div className="fihs-feature">
                       <div className="fihs-feature-icon">
                          <img src={bell} alt="bell"/>
                       </div>
                       <p className="fihs-feature-title">{item.title}</p>
                    </div>
                    <p className="fihs-feature-description">{item.description}</p>
                 </div>
              ))}
              </div>
           </div>
           
        </div>
        </div>
        </>
    
}

export default FiveHeroSection;
