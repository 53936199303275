import React, { useState } from 'react';
import Parser from 'html-react-parser';
import { BASEAPIURL } from '../../config';
import aboutImg1 from '../../assets/images/about-img1.png';
// import aboutImg2 from '../../assets/images/about-img2.png';
import slide5 from '../../assets/images/slider-img5.png';

function AboutBlockTwo({ className }) {
    const [styleBTN, setstyleBTN] = useState({});
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState();
    const [user, SetUser] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        message: '',
    });
    let name;
    let value;
    const HandleForm = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;
        SetUser({ ...user, [name]: value });
    };
    const SendData = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/enquiry/send-contact-us`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user),
            });
            setstyleMSG({ display: 'block' });
            setstyleBTN({ display: 'none' });
            SetUser({
                fname: '',
                lname: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
            });
            const resJson = await res.json();
            if (resJson.status === 1) {
                setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                console.log(resJson.data);
            } else {
                setMessage(Parser(`<div class+"alert alert-danger">${resJson.message}</div>`));
            }
            setstyleBTN({ display: '' });
        } catch (err) {
            setstyleBTN({ display: '' });
            setMessage(Parser(`<div class="alert alert-danger">${err}</div>`));
        }
    };
    return (
        <>
            <section className={`appie-about-area d-none ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-content pr25">
                                        <h2 className="title">
                                            <span>My Money Panda</span>
                                        </h2>
                                        <p>
                                            We are your one-stop shop for navigating the complex
                                            personal finance and investment world. Our platform
                                            helps you invest your finances wisely and keeps track of
                                            all your investments. We also provide educational
                                            resources and informative articles to help you become a
                                            financial expert.
                                        </p>
                                        <p>
                                            Money can be confusing and overwhelming for many people.
                                            That's where we come in! We break down complex financial
                                            concepts and present them to you in a way that's easy to
                                            understand. Our goal is to help you develop healthy
                                            financial habits and make your money work for you, not
                                            the other way around.
                                        </p>
                                        <p>
                                            Our platform offers various resources such as
                                            informative blogs, interactive tools and accurate
                                            calculators. We cover budgeting, saving,investing,
                                            retirement planning, and more. Our team of financial
                                            experts is committed to providing you with up-to-date
                                            information so that you can make informed decisions
                                            about your finances.
                                        </p>
                                        <p>
                                            Financial literacy is essential for everyone, regardless
                                            of age or income level. Our platform is accessible to
                                            people of all backgrounds and experience levels. So
                                            whether you're just starting your financial journey or
                                            are a seasoned investor, My Money Panda is here to help
                                            you achieve your goals.
                                        </p>
                                        <p>
                                            Join the My Money Panda community today and take control
                                            of your financial future.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-thumb">
                                        <img src={slide5} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center d-none">
                                <div className="col-lg-12">
                                    <div className="about-content pr0 mt-50">
                                        <h2 className="title text-uppercase">
                                            What <span>We Do</span>
                                        </h2>
                                        <h4 className="sub-title color-blue">
                                            Let's learn the relevant part, unlearn the myths and
                                            inculcate habits that make money work for you not the
                                            other way round.
                                        </h4>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged.
                                            It was popularised in the 1960s with the release of
                                            Letraset sheets containing Lorem Ipsum passages, and
                                            more recently with desktop publishing software like
                                            Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mt-40">
                                <div className="col-lg-7">
                                    <div className="about-content pr25">
                                        <div className="item-box">
                                            <ul>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Portoflio Management
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Asset Allocation
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Tax Advise and Saving
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Retirement Planning
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Children Education Planning
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Mutual Fund Advisory
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Life Insurance
                                                </li>
                                                <li>
                                                    <i className="far fa-check" />
                                                    Medical Insurance
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-thumb">
                                        <img src={aboutImg1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="about-content pr0 mt-70">
                                        <div className="message-box">
                                            <span className="head-title">
                                                Simplify your relationship with money today!
                                            </span>
                                            <h2 className="title">
                                                <span>Wealthy Nivesh</span> Experts
                                            </h2>
                                            <div className="about-form">
                                                <h4>Get in touch with us.</h4>
                                                <form method="POST" className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-box">
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                value={user.fname}
                                                                required
                                                                placeholder="Enter Your Name"
                                                                onChange={HandleForm}
                                                            />
                                                            <label>Your Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 d-none">
                                                        <div className="form-box">
                                                            <input
                                                                type="text"
                                                                name="lname"
                                                                value={user.lname}
                                                                placeholder="Enter Your Last Name"
                                                                onChange={HandleForm}
                                                            />
                                                            <label>Last Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-box">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                value={user.email}
                                                                placeholder="Enter Your Email Id"
                                                                onChange={HandleForm}
                                                            />
                                                            <label>Email</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-box">
                                                            <input
                                                                required
                                                                className="phonenumber"
                                                                type="number"
                                                                name="phone"
                                                                value={user.phone}
                                                                placeholder="mobile number"
                                                                onChange={HandleForm}
                                                            />
                                                            <label>Mobile</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-box">
                                                            <textarea
                                                                name="message"
                                                                value={user.message}
                                                                onChange={HandleForm}
                                                                placeholder="Type Your Message"
                                                            ></textarea>
                                                            <label>Message</label>
                                                        </div>
                                                        <div
                                                            className="col-md-12"
                                                            style={{ clear: 'both' }}
                                                        >
                                                            <div style={styleMSG}>{message}</div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={styleBTN}
                                                        className="col-md-12 text-center"
                                                    >
                                                        <input
                                                            type="submit"
                                                            name="submit"
                                                            value="Send Message"
                                                            onClick={SendData}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutBlockTwo;
