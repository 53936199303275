import React, { useEffect } from 'react';
import StickyMenu from '../../lib/StickyMenu';
import HeaderMain from './HeaderMain';
import HeaderUser from './HeaderUser';

let jwttoken = localStorage.getItem('jwttoken');

function Header({ action }) {
    useEffect(() => {
        StickyMenu();
        jwttoken = localStorage.getItem('jwttoken');
    }, []);
    return (
        <>
            {jwttoken !== 0 && jwttoken !== '' && jwttoken !== '0' && jwttoken !== null ? (
                <HeaderUser action={action} />
            ) : (
                <HeaderMain action={action} />
            )}
        </>
    );
}

export default Header;
