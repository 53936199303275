import React from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import SliderBlock from './SliderBlock';
// import VideoBlock from './VideoBlock';
// import BlogBlock from './BlogBlock';
import TeamBlock from './TeamBlock';
import TestimonialBlock from './TestimonialBlock';
import PartnersBlock from './PartnersBlock';
import DownloadBlock from './DownloadBlock';
import RegisteredBlock from './RegisteredBlock';


function Home() {
    const [drawer, drawerAction] = useToggle(false);


    return (
        <>
            <Helmet>
                <title>Invest in Mutual Funds & Manage your money | Moneypanda</title>
                <meta
                    name="description"
                    content="Explore the potential of mutual funds as smart investment options. Secure your financial future with My Money Panda. Start investing in mutual funds today!"
                />
                <meta
                    name="keywords"
                    content="My Moneypanda"
                />
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <SliderBlock />
            {/* <VideoBlock /> */}
            <TeamBlock />
            {/* <BlogBlock /> */}
            <TestimonialBlock />
            <PartnersBlock />
            <DownloadBlock />
            <RegisteredBlock />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Home;
