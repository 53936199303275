import React from 'react';
import checkcircle from "../../assets/images/mutualfundimg/check-circle.svg";
import managemoney from "../../assets/images/mutualfundimg/Manage money-amico 1.svg";
import './css/secondherosection.css'

function SecondHeroSection(){

    return <>
    <div className='shs-main'>
        <div className='shs-container'>
            <p id='sipsolutions'>
                SIP Solutions
            </p>
            <h2 id='shs-content'>
                Create your personalized mutual funds plan in less than 5 minutes
            </h2>
            <div className='shs-secondcontainer'>
                <div className='manageimagediv'>
                    <img src={managemoney} alt="managemoney"/>
                </div>
                <div className='checkdiv'>
                    <div className='checktextdiv' id='margindiv'>
                        <div className='check-container' >
                            <img src={checkcircle} alt="checkcircle"/>
                        </div>
                        <p>Tailored SIP solutions to match your goals</p>
                    </div>
                    <div className='checktextdiv' id='secondcheckdiv'>
                        <div className='check-container' id='checkcircletwo'>
                            <img src={checkcircle} alt="checkcircle" />
                        </div>
                        <p>Build diverse investment Portfolios.</p>
                    </div>
                </div>
                
            </div>
            <a href='/review'>
                <div className='buildbuttondiv'>
                    Build Now
                </div>  
            </a>
        </div>    
    </div>
    </>
}

export default SecondHeroSection;