import React from 'react';
import { PortfolioType } from '../../administrator/pages/Dashboard/Investors/masterData';

// let runIndex = 0;
function PageFour({
    // SubmitWorkersData,
    PortfolioList,
    handleAddPortfolio,
    RefreshPortfolioList,
    handleEditPortfolio,
    HandleArrayForm,
    onButtonClick,
    handleRemovePortfolio,
    Delete2ReviewRnvestment,
    isEmbed,
}) {
    const DeleteIndex = '_id';
    return (
        <main className="form-area">
            <div>
                {PortfolioList.map((sPortFol, run) => (
                    <div className="row mb-20 mx-0 align-items-center" key={sPortFol.index}>
                        <div className="col-lg-4 p-0 pr-lg-4">
                            <div className="selectdiv">
                                <select
                                    className="mb-lg-0"
                                    name="type"
                                    value={sPortFol.type}
                                    onChangeCapture={(e) => {
                                        RefreshPortfolioList();
                                        handleEditPortfolio(run, e.target.name, e.target.value);
                                        HandleArrayForm('PortfolioList', PortfolioList);
                                    }}
                                >
                                    <option value="0">Select Type</option>
                                    {PortfolioType.map((single) => (
                                        <option value={single.id} key={single.id}>
                                            {single.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <input
                                type="number"
                                name="amount"
                                placeholder="Enter Amount"
                                value={sPortFol.amount}
                                onChange={(e) => {
                                    RefreshPortfolioList();
                                    handleEditPortfolio(run, e.target.name, e.target.value);
                                    HandleArrayForm('PortfolioList', PortfolioList);
                                }}
                            />
                        </div>
                        <div className="col-lg-2 text-right">
                            <button
                                type="button"
                                className="btn text-danger"
                                style={{
                                    height: '40px',
                                    display: sPortFol.index === 1 ? 'none' : '',
                                }}
                                onClick={() => {
                                    handleRemovePortfolio(sPortFol.index);
                                    if (sPortFol[DeleteIndex]) {
                                        Delete2ReviewRnvestment('portfolio', sPortFol[DeleteIndex]);
                                    }
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                ))}

                <div className="text-right">
                    <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() => {
                            HandleArrayForm('PortfolioList', PortfolioList);
                            handleAddPortfolio(Number(PortfolioList.length) + 1);
                        }}
                    >
                        <i className="fa fa-plus"></i>
                        &nbsp;More Portfolio
                    </button>
                </div>
                <div
                    className={Number(isEmbed) === 1 ? 'd-none' : 'mt-60 text-lg-right text-center'}
                >
                    <input
                        type="button"
                        value="Back"
                        onClick={() => {
                            HandleArrayForm('PortfolioList', PortfolioList);
                            onButtonClick('pagethree');
                        }}
                        className="main-btn skip mb-20"
                    />
                    <input
                        type="button"
                        value="Next"
                        onClick={() => {
                            HandleArrayForm('PortfolioList', PortfolioList);
                            onButtonClick('pageuser');
                            // SubmitWorkersData();
                        }}
                        className="main-btn mb-20"
                    />
                </div>
            </div>
        </main>
    );
}

export default PageFour;
