import React from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import BlogBlock from './BlogBlock';
import DownloadBlock from '../Home/DownloadBlock';
import RegisteredBlock from '../Home/RegisteredBlock';

function Blog() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Helmet>
            <title>Blog: Expert Financial Insights & Tips | My Money Panda</title>
            <meta
                name="description"
                content="Discover top financial strategies on My Money Panda Blog. Get insights on mutual funds, stock market tips, and investment guides based on expert advice. "
            />
            <meta
                name="keywords"
                content="My Moneypanda"
            />
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <BlogBlock />
            <DownloadBlock className="pt-0" />
            <RegisteredBlock />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Blog;
