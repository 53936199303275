

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Parser from 'html-react-parser';
import loginIcon1 from '../../assets/images/login-icon1.png';
import loginIcon2 from '../../assets/images/login-icon2.png';
import { BASEAPIURL, BASEURL, setCookie } from '../../config';

function Login() {
    const [lfVisibility, setlfVisibility] = useState('block');
    const [fpVisibility, setfpVisibility] = useState('none');
    const [styleBTN, setstyleBTN] = useState({ display: 'block' });
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordType, setPasswordType] = useState('password');
    const [message, setMessage] = useState('');
    const [Remembeme, setRemembeme] = useState(1);
    // console.log(getCookie('token'));
    const temp1 = 9999 + Math.random() * (9999999999 - 9999);
    const d = new Date();
    const temp = d.getMilliseconds() + temp1;
    const handleSubmit = async (e) => {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        setstyleBTN({ display: 'none' });
        let fordays = 1;
        try {
            const res = await fetch(`${BASEAPIURL}/user/login?v=${temp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Username,
                    password: Password,
                    rv_invt_id: localStorage.getItem('rv_invt_id'),
                }),
            });
            console.log(res, 'res')
            const resJson = await res.json();
            console.log(resJson, 'resJson')
            if (res.status === 200) {
                setstyleBTN({ display: 'block' });
                if (resJson.status === 1) {
                    setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                    if (Remembeme === 1) {
                        fordays = 30;
                    } else {
                        fordays = 1;
                    }
                    console.log(fordays);
                    setCookie('rytoken', resJson.jwttoken, fordays);
                    localStorage.setItem('fname', resJson.data.fname);
                    localStorage.setItem('email', resJson.data.email);
                    localStorage.setItem('token', resJson.token);
                    localStorage.setItem('jwttoken', resJson.jwttoken);
                    setTimeout(() => {
                        window.location.href = `${BASEURL}user-panel`;
                    }, 100);
                    console.log(BASEURL);
                } else {
                    setMessage(Parser(`<div class="alert alert-danger">${resJson.message}</div>`));
                }
            } else {
                setMessage(Parser(`<div class+"alert alert-danger">${resJson.message}</div>`));
            }
        } catch (err) {
            setMessage(Parser(`<div class="alert alert-danger">${err}</div>`));
        }
    };
    return (
        <>
            <section className="login-area">
                <div className="container">
                    {/* <div
                        className="p-3 text-center"
                        style={{
                            // background: `url(${IMGBASEAPIURL})`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2>Login</h2>
                    </div> */}
                    <div className="row justify-content-end">
                        <div className="col-lg-5">
                            <form className="m-4 p-4 fpform" style={{ display: fpVisibility }}>
                                <div className="form-group mt-4">
                                    {/* <label> */}
                                    {/* <b>Email ID</b> */}
                                    {/* </label> */}
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email ID"
                                        className="form-control"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    style={{ width: '100%' }}
                                >
                                    Reset Password
                                </button>
                                <div className="text-right mt-2">
                                    <a
                                        href="#"
                                        className="text-dark"
                                        onClick={() => {
                                            setlfVisibility('block');
                                            setfpVisibility('none');
                                        }}
                                    >
                                        <b>Back to Login</b>
                                    </a>
                                </div>
                            </form>
                            <div className="loginBox">
                                <form
                                    method="post"
                                    className="m-4 p-4 fpform"
                                    onSubmit={handleSubmit}
                                    style={{ display: lfVisibility }}
                                >
                                    <input
                                        type="email"
                                        required
                                        name="email"
                                        placeholder="Enter Email ID"
                                        className="form-control"
                                        onChange={(e) => setUsername(e.target.value)}
                                        value={Username}
                                    />
                                    <div
                                        className="view-password d-flex"
                                        style={{ alignItems: 'center', position: 'relative' }}
                                    >
                                        <input
                                            type={PasswordType}
                                            required
                                            name="password"
                                            placeholder="Enter Password"
                                            className="form-control"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={Password}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '20px',
                                            }}
                                        >
                                            <i
                                                className="fa fa-eye"
                                                onClick={() => setPasswordType('text')}
                                                style={{
                                                    display: PasswordType === 'text' ? 'none' : '',
                                                }}
                                            ></i>
                                            <i
                                                className="fa fa-eye-slash"
                                                onClick={() => setPasswordType('password')}
                                                style={{
                                                    display:
                                                        PasswordType === 'password' ? 'none' : '',
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            className="text-dark"
                                            onClick={() => {
                                                setlfVisibility('none');
                                                setfpVisibility('block');
                                            }}
                                        >
                                            <span>Forgot password?</span>
                                        </a>
                                    </div>
                                    <div className="text-right mt-2 d-none">
                                        <input
                                            type="checkbox"
                                            value="1"
                                            name="remembeme"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setRemembeme(1);
                                                } else {
                                                    setRemembeme(0);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div style={{ clear: 'both' }}>
                                        <div style={styleMSG}>{message}</div>
                                    </div>
                                    <div style={styleBTN} className="col-md-12 text-center">
                                        <input type="submit" name="submit" value="Login" />
                                    </div>
                                </form>
                                <div className="divider">
                                    <b>OR</b>
                                </div>
                                <div className="socialLogin">
                                    <a href="#" className="socialIcon">
                                        <img src={loginIcon1} alt="" />
                                    </a>
                                    <a href="#" className="socialIcon">
                                        <img src={loginIcon2} alt="" />
                                    </a>
                                </div>
                                <div className="bottomBox">
                                    <p>
                                        By proceeding, I agree to <a href="#">T&C</a>,&nbsp;
                                        <a href="#">Privacy Policy</a> and&nbsp;
                                        <a href="#">Tariff Rates</a>
                                    </p>
                                </div>
                                <div className="signupBox">
                                    <p>
                                        Not registered yet?&nbsp;
                                        <NavLink to="/sign-up">Create Account</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
