import React, { useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import '../../administrator.css';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Drawer from '../../../Mobile/DrawerAdmin';
import Header from '../../../Helper/Header';
import Footer from '../../../Helper/Footer';

const ProfileMenu = React.lazy(() => import('./ProfileMenu'));
const Dashboard = React.lazy(() => import('./Dashboard'));

const ListBlogs = React.lazy(() => import('./ListBlogs'));

const AdminList = React.lazy(() => import('./ListAdmin'));

const ListUser = React.lazy(() => import('./Investors/ListUser'));
const ListInvestmentProfile = React.lazy(() => import('./Investors/ListInvestmentProfile'));
const TeamMembers = React.lazy(() => import('./TeamMembersList'));

const ListTestimonials = React.lazy(() => import('./ListTestimonials'));
const ListOurPartners = React.lazy(() => import('./OurPartnersList'));
const ListHomeSlider = React.lazy(() => import('./ListHomeSlider'));
const ListTopMessage = React.lazy(() => import('./ListTopMessage'));
const ListFaqs = React.lazy(() => import('./ListFaqs'));

const ListBlogCategories = React.lazy(() => import('./ListBlogCategories'));
const ListContactEnquiry = React.lazy(() => import('./ListContactEnquiry'));
const ListSubscribers = React.lazy(() => import('./ListSubscribers'));

const InfoPrivacy = React.lazy(() => import('./InfoPrivacy'));
const InfoTerms = React.lazy(() => import('./InfoTerms'));
const InfoTariffRatess = React.lazy(() => import('./InfoTariffRatess'));

const SeoSettings = React.lazy(() => import('./SeoSettings'));

// admin menu will be here

function AdminDashboard() {
    const { PageName } = useParams();
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        const checking1 = localStorage.getItem('usertype') ? localStorage.getItem('usertype') : '';
        if (checking1 !== 'admin') {
            const d = new Date();
            const temp = d.getMilliseconds();
            window.location.href = `admin-login?action=loginhere&token=${temp}`;
        }
        // checked
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container-fluid pt-4 pb-4">
                    <div className="row">
                        <div className="hideonmobile col-md-3">
                            <Suspense fallback={<div>Loading...</div>}>
                                <ProfileMenu isDrawer={0} forpage={PageName} />
                            </Suspense>
                        </div>

                        <div className="col-md-9">
                            {PageName === undefined ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Dashboard forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'index' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Dashboard forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'set-term' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <InfoTerms forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'set-tariffRates' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <InfoTariffRatess forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'seo-settings' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SeoSettings forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'set-privacy' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <InfoPrivacy forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'myblog' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListBlogs forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'admins-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <AdminList forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'users-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListUser forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'InvestmentProfile-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListInvestmentProfile forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'team-members' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <TeamMembers forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'testimonials-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListTestimonials forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'our-partners-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListOurPartners forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'homeslider-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListHomeSlider forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                            {PageName === 'manage-topmessage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListTopMessage forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'faqs-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListFaqs forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'blogcategories-manage' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListBlogCategories forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'contact-enquiry' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListContactEnquiry forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}

                            {PageName === 'subscribers' ? (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ListSubscribers forpage={PageName} />
                                </Suspense>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AdminDashboard;
