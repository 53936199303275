import React from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';
import Faq from './Faq';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>  
        <Helmet>
                <title>My Money Panda FAQs - Your Guide to Financial Solutions</title>
                <meta
                    name="description"
                    content="Discover answers to all your financial queries with My Money Panda's FAQ. Expert insights on investments, savings, and more to secure your financial future"
                />
                <meta
                    name="keywords"
                    content="My Moneypanda"
                />
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Faq />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
