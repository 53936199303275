import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Card from 'react-bootstrap/Card';
// import Parser from 'html-react-parser';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GoalTracker = ({ dataValue, calculateSip, numberWithCommas }) => {
    const sliderRef = useRef();
    // const [SliderData, setSliderData] = useState([]);
    const teamslider = {
        autoplay: true,
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: dataValue.length > 2 ? 3 : dataValue.length,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        // console.log(temp);
        // FetchTest();
    }, []);
    return (
        <div className="row mt-lg-5 mt-20">
            <div className="col-lg-12">
                <div className="slider-item">
                    <Slider ref={sliderRef} {...teamslider}>
                        {dataValue.map((singi) => (
                            <>
                                {singi.filled === 1 ? (
                                    <div className="content" key={singi.image}>
                                        <div className="userBox">
                                            <div>
                                                <Card
                                                    style={{
                                                        width: '22rem',
                                                        borderTopLeftRadius: '30px',
                                                        borderTopRightRadius: '30px',
                                                    }}
                                                >
                                                    <Card.Body
                                                        className="accrodion-inner-goal-tracker"
                                                        style={{ border: 'none', padding: '0' }}
                                                    >
                                                        <Card.Title className="accrodion-title-goal-tracker">
                                                            <div className="goalBox head d-flex col-12">
                                                                <div
                                                                    className="textBox d-flex col-6"
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <i
                                                                            className={`fal ${singi.icon}`}
                                                                            style={{
                                                                                fontSize: '40px',
                                                                                background: 'white',
                                                                                padding: '8px',
                                                                                border: '2px solid blue',
                                                                                borderRadius:
                                                                                    '30px',
                                                                                marginRight: '5px',
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div className="d">
                                                                        <span
                                                                            style={{
                                                                                fontSize: '18px',
                                                                            }}
                                                                        >
                                                                            {singi.name}
                                                                        </span>
                                                                        <Card.Subtitle className="mb-2 text-muted">
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '10px',
                                                                                }}
                                                                            >
                                                                                Goal item
                                                                            </span>
                                                                        </Card.Subtitle>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="col-6"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        position: 'absolute',
                                                                        marginLeft: '220px',
                                                                        marginTop: '20px',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    <Link
                                                                        // to="/review-investment"
                                                                        to="/review"
                                                                        target="_BLANK"
                                                                        onClick={() => {
                                                                            localStorage.setItem(
                                                                                'ReviewInvestmentPage',
                                                                                'pagethree'
                                                                            );
                                                                        }}
                                                                        style={{ color: 'white' }}
                                                                    >
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </Card.Title>
                                                        <Table striped hover>
                                                            <thead
                                                                className="goal-tracker-head"
                                                                style={{ fontSize: '14px' }}
                                                            >
                                                                <tr>
                                                                    <th>Goal Value</th>
                                                                    <th>Duration</th>
                                                                    <th>Sip required</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ fontSize: '14px' }}>
                                                                <tr>
                                                                    <td>
                                                                        <div className="goal-tracker-btn col-6">
                                                                            ₹&nbsp;
                                                                            {singi.presentValue}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="goal-tracker-btn col-6">
                                                                            {singi.target} Yrs
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="goal-tracker-btn col-6">
                                                                            {numberWithCommas(
                                                                                calculateSip(
                                                                                    singi.presentValue,
                                                                                    singi.ValueOn,
                                                                                    singi.target,
                                                                                    singi.Inflation,
                                                                                    singi.ROI
                                                                                )
                                                                            )}
                                                                            /Month
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <div>
                                                            <div
                                                                className="textBox d-flex"
                                                                style={{
                                                                    margin: '18px',
                                                                }}
                                                            >
                                                                <div
                                                                    className="ROI-btn skip"
                                                                    style={{
                                                                        margin: '5px',
                                                                    }}
                                                                >
                                                                    <i className="far fa-bar-chart"></i>
                                                                    Inflation*
                                                                    <b>{singi.Inflation}%</b>
                                                                </div>
                                                                <div
                                                                    className="ROI-btn skip mr-0"
                                                                    style={{
                                                                        margin: '5px',
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fal fa-analytics"
                                                                        style={{ margin: '5px' }}
                                                                    />
                                                                    &nbsp;ROI*
                                                                    <b>{singi.ROI} %</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <i className="far fa-bar-chart"></i>
                                                        {/* <Card.Text>
                                                    </Card.Text> */}
                                                        {/* <Card.Text>
                                                        <div className="goalBox">
                                                            <div className="SIP">
                                                                <div className="textBox1">
                                                                    <div className="sip-btn mt-10">
                                                                        <span>
                                                                            SIP Required &nbsp;
                                                                            <i
                                                                                className="fa fa-question-circle"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '25px',
                                                                                    marginRight:
                                                                                        '7px',
                                                                                    color: 'white',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        <div className="vr"></div>
                                                                        <span>
                                                                            <i
                                                                                className="far fa-rupee-sign"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '25px',
                                                                                    marginRight:
                                                                                        '7px',
                                                                                    color: '#000',
                                                                                }}
                                                                            />
                                                                            / Month
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Text> */}
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default GoalTracker;
