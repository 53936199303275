import React from "react";
import { Chart } from "react-google-charts";

const GoalCalculationGraph = ({ graphData }) => {
  const options = {
    title: "Investment and Gain Graph",
    chartArea: { width: "75%" },
    isStacked: true,
    orientation: "horizontal",
    hAxis: {
      title: "Year",
      minValue: 0,
    },
    vAxis: {
      title: "Amount",
      // minValue: 2024,
    },
    series: {
      0: { color: "415fe6" }, // Color for the first series (Invested)
      1: { color: "00ebb4" }, // Color for the second series (Gain)
    },
  };

  return (
    <div className="bar_chart">
      <Chart
        chartType="LineChart"
        width="100%"  // Adjusted width to 100% for responsiveness
        height="400px"
        data={[
          ["Year", "Invested", "Gain"],
          ...graphData.years.map((year, index) => [
            year.toString(),
            graphData.investedGraph[index],
            graphData.gainGraph[index],
          ]),
        ]}
        options={options}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  );
};

export default GoalCalculationGraph;
