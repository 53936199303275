import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEAPIURL, BASEURL } from '../../config';

const Token = localStorage.getItem('token');
const jwttoken = localStorage.getItem('jwttoken');
const LSName = localStorage.getItem('fname');
const LSEmail = localStorage.getItem('email');

function Nominee() {
    // const [user, setUser] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState('');
    const [relation1, setRelation1] = useState('');
    const [applicable1, setApplicable1] = useState('');
    const [name1, setName1] = useState('');
    // const [shownominee, setShownominee] = useState(false);

    // const handleClick = () => {
    //     setShownominee(true);
    // };

    // const hidemessage = () => {
    //     setShownominee(false);
    // };

    useEffect(() => {
        if (Token && Token !== 0 && Token !== '0') {
            console.log(Token);
        } else if (jwttoken && jwttoken !== 0 && jwttoken !== '0') {
            console.log(jwttoken);
        } else {
            window.location.href = `${BASEURL}login?action=login&message=Please Login to Continue`;
        }
        const fetchUser = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/user/getProfile`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        token: Token,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    // setUser(resJson.user);
                    // setOldPassword(resJson.oldpassword);
                    // setNewPassword(resJson.newpassword);
                    // setConfirmPassword(resJson.confirmpassword);
                    console.log(resJson.data);
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                } else {
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-danger">${resJson.message}</div>`));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchUser();
    }, []);

    const DefaultNominee = [
        {
            index: 1,
            type: 1,
            subType: 0,
            amount: '',
        },
        {
            index: 2,
            type: 2,
            subType: 0,
            amount: '',
        },
    ];

    const [NomineeList, setNomineeList] = useState(DefaultNominee);

    const handleAddNominee = (index) => {
        // ExpensesTypeSelected.push(1);
        setNomineeList((Nom) => [
            ...Nom,
            {
                index,
                type: 1,
                subType: '',
                amount: '',
            },
        ]);
    };
    // const handleEditNominee = (changeId, ind, val) => {
    //     NomineeList[changeId][ind] = val;
    // };
    const handleRemoveNominee = (ind) => {
        if (ind !== 1) {
            setNomineeList(NomineeList.filter((item) => item.index !== ind));
        }
    };
    // const RefreshNomineeList = () => {
    //     const temp = Number(NomineeList.length) + 1;
    //     handleAddNominee(temp);
    //     handleRemoveNominee(temp);
    // };

    function handleSubmit(e) {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        console.log(Token, name1, relation1, applicable1);
        const data = { token: Token, name1, relation1, applicable1 };
        fetch(`https://services.mymoneypanda.in/api/usercontact/createNominee`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log(result);
            result.json().then((resp) => {
                setMessage(Parser(`<div class="alert alert-success">${resp.message}</div>`));
                console.log(resp);
            });
        });
    }

    // function handleCancel() {
    //     setTimeout(() => {
    //         window.location.href = `${BASEURL}user-panel`;
    //     }, 100);
    //     console.log(BASEURL);
    // }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile-block">
                                <div className="leftBox">
                                    <img src={userImg1} alt="" />
                                    <h5>{LSName}</h5>
                                    {/* <h5>{user.fname}</h5> */}
                                    <a href="#">
                                        <i className="far fa-envelope" />
                                        {LSEmail}
                                    </a>
                                    <hr />
                                    <div className="navBox">
                                        <NavLink exact to="/profile">
                                            <i className="far fa-user" />
                                            Basic Details
                                        </NavLink>
                                        {/* <NavLink exact to="/ContactDetail">
                                            <i className="far fa-address-book" />
                                            &nbsp;Contact Details
                                        </NavLink>
                                        <NavLink exact to="/BankDetail">
                                            <i className="far fa-landmark"></i>
                                            Bank Details
                                        </NavLink>
                                        <NavLink exact to="/nominee">
                                            <i className="far fa-user-plus" />
                                            Nominee Details
                                        </NavLink> */}
                                        <NavLink exact to="/change-password">
                                            <i className="far fa-lock" />
                                            Change Password
                                        </NavLink>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                localStorage.removeItem('jwttoken');
                                                window.location.href = BASEURL;
                                            }}
                                        >
                                            <div className="iconBox">
                                                <i className="fal fa-sign-out" />
                                            </div>
                                            <div>Logout</div>
                                            <i className="far fa-angle-right" />
                                        </NavLink>
                                    </div>
                                </div>
                                <hr className="d-lg-none d-block mt-50 mb-50" />
                                <div className="rightBox">
                                    <h6>Nominee Details</h6>
                                    <form method="POST" onSubmit={handleSubmit}>
                                        <div className="form-area mb-20">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            name="Name1"
                                                            // value={user.password}
                                                            placeholder="Nominee Name"
                                                            onChange={(e) => {
                                                                setName1(e.target.value);
                                                            }}
                                                        />
                                                        <label>Nominee Name</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Relation with Nominee"
                                                            onChange={(e) => {
                                                                setRelation1(e.target.value);
                                                            }}
                                                        />
                                                        <label>Relation with Nominee</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Applicable %"
                                                            onChange={(e) => {
                                                                setApplicable1(e.target.value);
                                                            }}
                                                        />
                                                        <label> Nominee Applicable %</label>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                                {/* <div className="row"> */}
                                                <div
                                                    className="col-md-1 d-flex"
                                                    style={{
                                                        fontSize: '15px',
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name="minor"
                                                        value="minor-account"
                                                        placeholder="Minor-account"
                                                        style={{ height: '20px' }}
                                                    />
                                                    <span>Minor-account</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <h6>Add Another Nominee</h6>
                                            </div>
                                            <div className="mb-50">
                                                {NomineeList &&
                                                    NomineeList.map((single) => (
                                                        <div className="row" key={single.index}>
                                                            <div className="row mb-20 mx-0 align-items-center">
                                                                <div className="col-lg-4 p-0 pr-lg-4">
                                                                    <div className="selectdiv">
                                                                        <div className="form-box">
                                                                            <input
                                                                                type="text"
                                                                                name="Name1"
                                                                                // value={user.password}
                                                                                placeholder="Nominee Name"
                                                                                onChange={(e) => {
                                                                                    setName1(
                                                                                        e.target
                                                                                            .value
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <label>
                                                                                Nominee Name
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 p-0 pr-lg-4">
                                                                    <div className="selectdiv">
                                                                        <div
                                                                            className="form-box"
                                                                            style={{
                                                                                alignItems:
                                                                                    'center',
                                                                                position:
                                                                                    'relative',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                name="confirmpassword"
                                                                                // value={user.confirmpassword}
                                                                                placeholder="Relation with Nominee"
                                                                                onChange={(e) => {
                                                                                    setRelation1(
                                                                                        e.target
                                                                                            .value
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <label>
                                                                                Relation with
                                                                                Nominee
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="form-box">
                                                                        <input
                                                                            type="text"
                                                                            name="confirmpassword"
                                                                            placeholder="Applicable %"
                                                                            onChange={(e) => {
                                                                                setApplicable1(
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label>
                                                                            Nominee Applicable %
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1 p-0 mb-40">
                                                                    <button
                                                                        type="button"
                                                                        className="btn text-danger"
                                                                        style={{
                                                                            display:
                                                                                single.index === 1
                                                                                    ? 'none'
                                                                                    : '',
                                                                        }}
                                                                        onClick={() => {
                                                                            handleRemoveNominee(
                                                                                single.index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                <div className="text-left">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        onClick={() => {
                                                            handleAddNominee(
                                                                Number(NomineeList.length) + 1
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                        &nbsp;add Nominee
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12" style={{ clear: 'both' }}>
                                                <div style={styleMSG}>{message}</div>
                                            </div>
                                            <div className="col-lg-12 mt-20 text-lg-left text-center">
                                                <input type="submit" name="submit" value="Save" />
                                                <input
                                                    type="submit"
                                                    name="submit"
                                                    value="cancel"
                                                    className="cancel"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
}

export default Nominee;
