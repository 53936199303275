import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import Information from './Information';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Information />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
