import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASEAPIURL } from '../../../config';

function PageUser({
    onButtonClick,
    HandleForm,
    FormData,
    isEmbed,
    SubmitWorkersData,
    AjaxClass,
    AjaxMsg,
    BASEURL,
    IsLoader,
}) {
    const [otpInput, setOtpInput] = useState('');
    const [otpVerifyError, setOtpVerifyError] = useState();
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [toggleMessage, setToggleMessage] = useState(false);
    // const handleClick = () => {
    //     setToggleMessage(true);
    //     window.history.pushState(null, '', `?steps=completion`);
    // };
    const [message, setMessage] = useState();
    const showMessage = () => {
        setMessage('Please Enter Your Details');
    };

    useEffect(() => {
        // Your code to execute on component mount (first time)
        console.log('Component mounted for the first time');
        // You can place your React code here
    }, []);

    const verifyOTP = async () => {
        try {
            const mobileNumber = FormData.userMobile;

            const otp = otpInput;
            const axiosConfig = {
                url: 'app/otp/verify-otp',
                method: 'post',
                baseURL: BASEAPIURL,
                data: {
                    mobileNumber,
                    otp,
                },
            };
            const response = await axios(axiosConfig);
            if (response.status === 200) {
                setToggleMessage(true);
                window.history.pushState(null, '', `?steps=completion`);
                SubmitWorkersData();
                setShowOtpInput(false);
                const utmSource = localStorage.getItem('utm_source');
                const leadSource = `FinancialPlan${utmSource === `null` ? '' : `-${utmSource}`}`;
                const crmData = {
                    data: [
                        {
                            Lead_Source: leadSource,
                            Last_Name: FormData?.userName,
                            Email: FormData?.userEmail,
                            Phone: FormData?.userMobile,
                            Description: '',
                            Company_Name: 'My Money Panda',
                        },
                    ],
                };
                const zohoCRMAxiosConfig = {
                    url: 'app/crm/create-crm-lead',
                    method: 'post',
                    baseURL: BASEAPIURL,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: crmData,
                };
                await axios(zohoCRMAxiosConfig);
            }
        } catch (error) {
            if (error.response.status === 400) {
                setOtpVerifyError('Invalid OTP!');
            } else {
                setOtpVerifyError('Internal server error');
            }
        }
    };
    const handleSubmit = async () => {
        try {
            const mobileNumber = FormData.userMobile;
            const axiosConfig = {
                url: 'app/otp/send-otp',
                method: 'post',
                baseURL: BASEAPIURL,
                params: {
                    mobileNumber,
                },
            };
            const response = await axios(axiosConfig);
            if (!(response.status === 200)) {
                console.log('Failed to send OTP');
            }
            setShowOtpInput(true);
        } catch (error) {
            console.error('Error while submitting data:', error);
        }
    };
    return (
        <main className="form-area" style={{ paddingTop: Number(isEmbed) === 1 ? '10px' : '' }}>
            <div>
                {toggleMessage ? (
                    <div>
                        {IsLoader ? (
                            <div className="text-center">
                                <img
                                    src={`${BASEURL}loader.gif?v=0.01`}
                                    alt=""
                                    style={{ width: '40px' }}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={`mt-20 ${AjaxClass}`}>
                            {console.log(AjaxMsg)}
                            Check your email to see your personalised financial plan.
                        </div>
                        <div
                            className={
                                Number(isEmbed) === 1 ? 'd-none' : 'mt-40 text-lg-right text-center'
                            }
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Link to="/" className="main-btn skip">
                                Home
                            </Link>
                            {/* <Link to="/sign-up" className="main-btn">Sign Up</Link> */}
                            <a href="https://invest.mymoneypanda.in/" className="main-btn">
                                Sign Up
                            </a>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="text-center">
                            <div className="mt-0">
                                <p
                                    style={{
                                        color: 'black',
                                        lineHeight: '30px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Thank You for creating Your Investment Profile.
                                    <br />
                                    Submit details to recieve your personalised financial plan over
                                    &nbsp;email.
                                </p>
                            </div>
                        </div>
                        <div
                            className="container mt-10"
                            style={{
                                boxShadow: '3px 3px 10px #eeeeee',
                                borderRadius: '10px',
                                border: '1px solid #f4f4f4',
                                padding: '40px 5px 40px 5px',
                            }}
                        >
                            <div
                                className="MessageBoxOne"
                                style={{ display: 'grid', justifyContent: 'center' }}
                            >
                                {/* <div style={{
                                        border: '2px solid #00ebb4',
                                        padding: '40px 70px 40px 70px',
                                        borderRadius: '15px',
                                    }}
                                > */}
                                <div className="row w-100">
                                    <div className="Col-lg-12 d-flex ">
                                        <label className="col-lg-4">Name :</label>
                                        <div className="col-lg-8 mb-10">
                                            <input
                                                name="userName"
                                                placeholder="Enter Your Name"
                                                required
                                                type="text"
                                                className="form-control"
                                                value={FormData.userName}
                                                onChange={(e) => {
                                                    HandleForm(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row w-100">
                                    <div className="Col-lg-12 d-flex ">
                                        <div className="d-flex">
                                            <label className="col-lg-4">Email :</label>
                                            <div className="col-lg-8 mb-10">
                                                <input
                                                    name="userEmail"
                                                    required
                                                    placeholder="Enter Your Email"
                                                    type="text"
                                                    className="form-control"
                                                    value={FormData.userEmail}
                                                    onChange={(e) => {
                                                        HandleForm(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row w-100">
                                    <div className="Col-lg-12 d-flex ">
                                        <div className="d-flex">
                                            <label className="col-lg-4">Mobile :</label>
                                            <div className="col-lg-8 mb-10 ">
                                                <input
                                                    name="userMobile"
                                                    required
                                                    placeholder="Enter Your Mobile"
                                                    type="text"
                                                    className="form-control"
                                                    value={FormData.userMobile}
                                                    onChange={(e) => {
                                                        if (!showOtpInput) {
                                                            HandleForm(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {showOtpInput && (
                                    <>
                                        <div className="row w-100">
                                            <div className="Col-lg-12 d-flex ">
                                                <div className="d-flex">
                                                    <label className="col-lg-4">OTP:</label>
                                                    <div className="col-lg-8 mb-10 ">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter OTP"
                                                            className="form-control"
                                                            value={otpInput}
                                                            onChange={(e) =>
                                                                setOtpInput(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="position-relative mb-10">
                                                    <div className="position-absolute top-0 end-0">
                                                        <div className="">
                                                            <input
                                                                type="button"
                                                                value="Verify"
                                                                className="form-btn"
                                                                onClick={verifyOTP}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {otpVerifyError && (
                                            <div className="row">
                                                <span className="alert alert-danger w-100">
                                                    {otpVerifyError}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* </div> */}
                            </div>
                            <div className="col-md-12" style={{ color: 'red' }}>
                                <div className="hello" style={{ textAlign: 'center' }}>
                                    {message}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                className={
                                    Number(isEmbed) === 1
                                        ? 'd-none'
                                        : 'mt-20 text-lg-right text-center'
                                }
                                style={{
                                    display: showOtpInput ? 'none' : '',
                                }}
                            >
                                <input
                                    type="button"
                                    value="Back"
                                    onClick={() => {
                                        // onButtonClick('pagefour');
                                        onButtonClick('pagefive');
                                    }}
                                    className="main-btn skip"
                                />
                                <input
                                    type="button"
                                    value="submit"
                                    onClick={() => {
                                        if (
                                            FormData.userName &&
                                            FormData.userName !== '' &&
                                            FormData.userEmail &&
                                            FormData.userEmail !== '' &&
                                            FormData.userMobile &&
                                            FormData.userMobile !== ''
                                        ) {
                                            // SubmitWorkersData();

                                            // handleClick();
                                            handleSubmit();
                                        } else {
                                            showMessage();
                                            // alert(2);
                                        }
                                    }}
                                    className="main-btn"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
}

export default PageUser;
