import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    AgeGroupArray,
    Occupation,
    ReactInvstDown,
} from '../../administrator/pages/Dashboard/Investors/masterData';

function PageOne({ onButtonClick, HandleForm, FormData, isEmbed }) {
    const [MoreQ, setMoreQ] = useState(0);

    return (
        <main className="form-area" style={{ paddingTop: Number(isEmbed) === 1 ? '10px' : '' }}>
            <div style={{ display: MoreQ === 1 ? 'none' : '' }}>
                <h6>Age</h6>
                <div className="mb-40">
                    {AgeGroupArray.map((singi) => (
                        <span key={singi.id}>
                            <input
                                type="radio"
                                id={`age_${singi.id}`}
                                checked={Number(FormData.ageGroup) === Number(singi.id)}
                                name="ageGroup"
                                value={singi.id}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                    }
                                }}
                            />
                            <label htmlFor={`age_${singi.id}`}>{singi.value}</label>
                        </span>
                    ))}
                </div>
                <h6>Occupation</h6>
                <div className="mb-40 selectdiv">
                    <select
                        name="occupation"
                        value={FormData.occupation}
                        onChangeCapture={(e) => {
                            HandleForm(e);
                        }}
                    >
                        {/* <option value="">Select Occupation</option> */}
                        {Occupation.map((singi) => (
                            <option key={singi.id} value={singi.id}>
                                {singi.value}
                            </option>
                        ))}
                    </select>
                </div>
                <div
                    className={Number(isEmbed) === 1 ? 'd-none' : 'mt-60 text-lg-right text-center'}
                >
                    <NavLink to="/">
                        <input
                            type="button"
                            value="Back"
                            onClick={() => setMoreQ(0)}
                            className="main-btn skip"
                        />
                    </NavLink>
                    <input
                        type="button"
                        value="Next"
                        onClick={() => onButtonClick('pagetwo')}
                        className="main-btn"
                    />
                </div>
                <div className="mt-40 text-lg-right d-none text-center">
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => setMoreQ(1)}
                        className="main-btn"
                    />
                </div>
            </div>
            <div style={{ display: MoreQ === 1 ? '' : 'none' }}>
                <div>
                    <p>
                        How would you react if your 1 lac investment drops to 80k upon market fall?
                    </p>
                    <div className="mb-40 innerDiv">
                        {ReactInvstDown.map((singi) => (
                            <p key={singi.id} className="RYCheckBoxCont">
                                <input
                                    type="radio"
                                    id={`ridown_${singi.id}`}
                                    checked={Number(FormData.reactinvstdown) === Number(singi.id)}
                                    name="reactinvstdown"
                                    value={singi.id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            HandleForm(e);
                                        }
                                    }}
                                />
                                <label htmlFor={`ridown_${singi.id}`}>&nbsp;</label>
                                {singi.value}
                            </p>
                        ))}
                    </div>
                    <p>Which return profile would you prefer?</p>
                    <div className="innerDiv">
                        <p className="RYCheckBoxCont">
                            <input
                                type="radio"
                                id="retPrgPrg_1"
                                checked={Number(FormData.retprf) === 1}
                                name="retprf"
                                value={1}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                    }
                                }}
                            />
                            <label htmlFor="retPrgPrg_1">&nbsp;</label>
                            <span>
                                100 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                112 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                106 <i className="far fa-chart-line-down" />
                            </span>
                            <span>
                                119 <i className="far fa-chart-line" />
                            </span>
                        </p>
                        <p className="RYCheckBoxCont">
                            <input
                                type="radio"
                                id="retPrgPrg_2"
                                checked={Number(FormData.retprf) === 2}
                                name="retprf"
                                value={2}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                    }
                                }}
                            />
                            <label htmlFor="retPrgPrg_2">&nbsp;</label>
                            <span>
                                100 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                115 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                120 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                110 <i className="far fa-chart-line-down" />
                            </span>
                        </p>
                        <p className="RYCheckBoxCont">
                            <input
                                type="radio"
                                id="retPrgPrg_3"
                                checked={Number(FormData.retprf) === 3}
                                name="retprf"
                                value={3}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                    }
                                }}
                            />
                            <label htmlFor="retPrgPrg_3">&nbsp;</label>
                            <span>
                                100 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                120 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                105 <i className="far fa-chart-line-down" />
                            </span>
                            <span>
                                115 <i className="far fa-chart-line" />
                            </span>
                        </p>
                        <p className="RYCheckBoxCont">
                            <input
                                type="radio"
                                id="retPrgPrg_4"
                                checked={Number(FormData.retprf) === 4}
                                name="retprf"
                                value={4}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                    }
                                }}
                            />
                            <label htmlFor="retPrgPrg_4">&nbsp;</label>
                            <span>
                                100 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                130 <i className="far fa-chart-line" />
                            </span>
                            <span>
                                120 <i className="far fa-chart-line-down" />
                            </span>
                            <span>
                                125 <i className="far fa-chart-line" />
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    className={Number(isEmbed) === 1 ? 'd-none' : 'mt-60 text-lg-right text-center'}
                >
                    <input
                        type="submit"
                        value="Back"
                        onClick={() => setMoreQ(0)}
                        className="main-btn skip"
                    />
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => onButtonClick('pagetwo')}
                        className="main-btn"
                    />
                </div>
            </div>
        </main>
    );
}

export default PageOne;
