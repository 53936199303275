import React, { useState } from 'react';
// import Parser from 'html-react-parser';
import {
    numberWithCommas,
    calculateSip,
    // Occupation,
    // ReactInvstDown,
} from '../../administrator/pages/Dashboard/Investors/masterData';
import deleteicon from "../../../assets/images/deleteicon.svg"

function PageThree({
    onButtonClick,
    HandleArrayForm,
    GolesList,
    handleEditGolesList,
    RefreshGolesList,
    Delete2ReviewRnvestment,
    isEmbed,
}) {
    console.log("Goes List",GolesList);
    // console.log("handleRemoveGolesList",handleRemoveGolesList)
    // console.log("handleEditGolesList",handleEditGolesList)
    const [message, setMessage] = useState();

    const showMessage = ()=>{
        setMessage('Value must be equal to or greater than 1');
    }
    const DeleteIndex = '_id';
    const [ShowGoalAnalysis, setShowGoalAnalysis] = useState('none');
    function GettotalSip() {
        let Total = 0;
        GolesList.map((single) => {
            if (Number(single.filled) === 1 && single.presentValue !== '' && single.target !== '') {
                Total += calculateSip(
                    single.presentValue,
                    single.ValueOn,
                    single.target,
                    single.Inflation,
                    single.ROI
                );
            }
            return '';
        });
        return Total;
    }
    return (
        <main className="form-area">
            <form>
                <div className="mb-40">
                    <div className="row mb-30 mx-0 align-items-center">
                        {GolesList.map((single, run) => (
                            <>
                                <div className="col-lg-4" key={single.id}>
                                    <div
                                        className="goalBlock mb-25"
                                        onClick={() => {
                                            if (GolesList[run].selected === 1) {
                                                RefreshGolesList();
                                                handleEditGolesList(run, 'selected', 0);
                                            } else {
                                                RefreshGolesList();
                                                handleEditGolesList(run, 'selected', 1);
                                                handleEditGolesList(run, 'filled', 0);
                                            }
                                        }}
                                    >
                                        <a className="add-btn">
                                            <i
                                                className="fal fa-plus-circle"
                                                style={{
                                                    display:
                                                        Number(GolesList[run].selected) === 1
                                                            ? 'none'
                                                            : '',
                                                }}
                                            />
                                            <i
                                                className="fal fa-check-circle text-success"
                                                style={{
                                                    display:
                                                        Number(GolesList[run].selected) === 1
                                                            ? ''
                                                            : 'none',
                                                }}
                                            />
                                        </a>
                                        <a href="#" className="text-btn">
                                            <i className={`fal ${single.img}`} />
                                            {single.name}
                                        </a>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 p-4 mb-4"
                                    style={{
                                        display:
                                            GolesList[run].selected === 1 &&
                                                GolesList[run].filled === 0
                                                ? ''
                                                : 'none',
                                        clear: 'both',
                                        background: '#f5f5f5',
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <b>Present Goal Value</b>
                                            <div style={{ position: 'relative' }}>
                                                <i
                                                    className="far fa-rupee-sign text-info"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        left: '10px',
                                                    }}
                                                ></i>
                                                <input
                                                    style={{
                                                        background: '#fff',
                                                        borderRadius: '5px',
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    name="presentValue"
                                                    value={single.presentValue}
                                                    placeholder="Target Value"
                                                    onChange={(e) => {
                                                        RefreshGolesList();
                                                        handleEditGolesList(
                                                            run,
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 d-none">
                                            <b>&nbsp;</b>
                                            <select
                                                type="number"
                                                className="form-control"
                                                name="ValueOn"
                                                value={single.ValueOn}
                                                placeholder="Target Value"
                                                onChangeCapture={(e) => {
                                                    RefreshGolesList();
                                                    handleEditGolesList(
                                                        run,
                                                        e.target.name,
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value="0">Thousand</option>
                                                <option value="1">Crore</option>
                                                <option value="2">Lacs</option>
                                                <option value="3">Thousand</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-5">
                                            <b>Target time (Yrs.)</b>
                                            <div style={{ position: 'relative' }}>
                                                <i
                                                    className="far fa-clock text-info"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        left: '10px',
                                                    }}
                                                ></i>
                                                <input
                                                    style={{
                                                        background: '#fff',
                                                        borderRadius: '5px',
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    name="target"
                                                    min="1"
                                                    value={single.target}
                                                    placeholder="Target time"
                                                    onChange={(e) => {
                                                        // if (e.target.value < 1) {
                                                        //     alert("Value must be equal to or greater than 1");
                                                        //     return;a
                                                        // }
                                                        RefreshGolesList();
                                                        handleEditGolesList(
                                                            run,
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                        
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4 mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={() => {
                                                handleEditGolesList(run, 'selected', 0);
                                                RefreshGolesList();
                                                if (single[DeleteIndex]) {
                                                    Delete2ReviewRnvestment(
                                                        'goles',
                                                        single[DeleteIndex]
                                                    );
                                                }
                                            }}
                                            style={{ marginRight: '5px', width: '150px' }}
                                        >
                                            Clear
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if(single.target < 1){
                                                    showMessage();
                                                    return;
                                                }
                                                handleEditGolesList(run, 'filled', 1);
                                                setShowGoalAnalysis('block');
                                                RefreshGolesList();
                                            }}
                                            style={{ marginLeft: '5px', width: '150px' }}
                                        >
                                            Add
                                        </button>
                                        <div className="col-md-12" style={{ color: 'red', marginTop: '10px' }} >
                                            <div className="hello" style={{ textAlign: 'center' }}>{message}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        display: Number(GettotalSip()) === 0 ? 'none' : '',
                    }}
                >
                    {/* <hr style={{ display: ShowGoalAnalysis }} className="mb-30" /> */}
                    <div
                        className="innerDiv"
                        style={{
                            display: Number(GettotalSip()) === 0 || GettotalSip() === '' ? 'none' : '',
                        }}
                    >
                        <h6 className="mb-2" style={{ textAlign: 'center', fontSize: '20px' }}>
                            Total SIP value for your Goals : &nbsp;
                            <span style={{ color: '#3D52ED' }}>₹{numberWithCommas(GettotalSip())}</span>
                        </h6>
                    </div>
                    <hr style={{ display: ShowGoalAnalysis }} className="mb-30" />
                    <h6 style={{ display: ShowGoalAnalysis }}>Goal Analysis</h6>
                </div>
                {GolesList.map((single, run) => (
                    <>
                        {single.detailed === 1 ? (
                            <div className="old-div">
                                <div
                                    className="innerDiv mb-3"
                                    key={single.index}
                                    style={{
                                        display: single.filled === 1 ? '' : 'none',
                                    }}
                                >
                                    <div className="goalBox head">
                                        <div>
                                            <i className={`fal ${single.index}`} />
                                            <div className="goalBoxOne">
                                                <i className={`fal ${single.icon}`}></i>
                                                <div className="textBox">{single.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="goalBox head">
                                        <div>
                                            <div className="textBox">
                                                <i
                                                    className="fal fa-clock"
                                                    style={{
                                                        fontSize: '22px',
                                                        fontweight: '700',
                                                        marginright: '5px',
                                                    }}
                                                />
                                                Target Value
                                                <div className="AmountBox">
                                                    <span
                                                        style={{
                                                            width: '180px',
                                                            boxShadow: '1px 2 px 1px black',
                                                            // background: 'white',
                                                        }}
                                                    >
                                                        <i className="far fa-rupee-sign" />
                                                        {numberWithCommas(single.presentValue)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="textBox">
                                                <i
                                                    className="fal fa-clock"
                                                    style={{
                                                        fontSize: '22px',
                                                        fontweight: '700',
                                                        marginright: '5px',
                                                    }}
                                                />
                                                Target Time
                                                <div className="AmountBox">
                                                    <span
                                                        style={{
                                                            width: '160px',
                                                            boxShadow: '1px 2 px 1px black',
                                                            // background: 'white',
                                                        }}
                                                    >
                                                        <i className="far fa-rupee-sign" />
                                                        {single.target} Years
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr className="mt-30 mb-30" /> */}
                                    <div className="goalBox">
                                        <div className="SIP">
                                            <div className="textBox1">
                                                <div className="sip-btn mt-10">
                                                    <span>
                                                        SIP Required &nbsp;
                                                        <i
                                                            className="fa fa-question-circle d-none"
                                                            style={{
                                                                fontSize: '25px',
                                                                marginRight: '7px',
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </span>
                                                    <span>
                                                        <i
                                                            className="far fa-rupee-sign"
                                                            style={{
                                                                fontSize: '25px',
                                                                marginRight: '7px',
                                                                color: '#000',
                                                            }}
                                                        />
                                                        {numberWithCommas(
                                                            calculateSip(
                                                                single.presentValue,
                                                                single.ValueOn,
                                                                single.target,
                                                                single.Inflation,
                                                                single.ROI
                                                            )
                                                        )}
                                                        &nbsp;Per Month
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-20 mb-20 d-none">
                                        <span
                                            style={{
                                                marginLeft: '52px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Assumed Inflation
                                        </span>
                                    </div>
                                    <div className="seek-bar-container mb-20 d-none">
                                        0%
                                        <input
                                            type="range"
                                            min="0"
                                            max="20"
                                            defaulValue={single.Inflation}
                                            onClick={(e) => {
                                                handleEditGolesList(
                                                    run,
                                                    'Inflation',
                                                    e.target.value
                                                );
                                                RefreshGolesList();
                                            }}
                                            className="seek-bar"
                                        />
                                        {single.Inflation}%{/* <p>Percentage: {value}</p> */}
                                    </div>
                                    <hr />
                                    <div className="mt-20">
                                        <span
                                            style={{
                                                marginLeft: '52px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Assumed ROI ({single.ROI}%)
                                        </span>
                                    </div>
                                    <div className="seek-bar-container mb-20">
                                        0%
                                        <input
                                            type="range"
                                            min="0"
                                            max="30"
                                            defaulValue={single.ROI}
                                            onClick={(e) => {
                                                handleEditGolesList(run, 'ROI', e.target.value);
                                                RefreshGolesList();
                                            }}
                                            className="seek-bar"
                                        />
                                        30%{/* <p>Percentage: {value}</p> */}
                                    </div>
                                    <hr className="mt-30 mb-30" />
                                    <div className="goalBox2 mt-30 mb-30">
                                        <p
                                            className="mb-4"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                                Disclaimer</p>
                                        <ul  style={{ fontSize: '14px' }}>
                                            <li>
                                                Inflation of 6% is assumed to calculate the future
                                                value of financial goals. This is based on the upper
                                                limit of RBI Inflation Target of 2% - 6%. We
                                                understand that inflation figures could vary for
                                                different assets.
                                            </li>
                                            <li>
                                                12% IRR (Internal Rate of Return) is assumed for
                                                deriving the SIP value. This is an assumption based
                                                on 10-year average NIFTY 50 returns range of 12% -
                                                14%.
                                            </li>
                                            <li>
                                                Past Performance is not indicative of future
                                                returns. Actual return would vary based on asset
                                                allocation, market cap allocation, time invested,
                                                fund performance, and market cycles.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="innerDiv mb-3"
                                key={single.index}
                                style={{
                                    display: single.filled === 1 ? '' : 'none',
                                    position: "relative"
                                }}
                            >
                                <div style={{width: "25px", position: "absolute", top: "0", right: "0", margin: "15px",cursor: "pointer"}}
                                onClick={() => {
                                    handleEditGolesList(run, 'filled', 0);
                                    setShowGoalAnalysis('block');
                                    RefreshGolesList();
                                }}
                                >
                                    <img src={deleteicon} alt="" style={{width: "100%"}}/>
                                </div>
                                <div className="goalBox head pagethreefirstbox">
                                    <div>
                                        <i className={`fal ${single.index}`} />
                                        <i className={`fal ${single.icon}`}></i>
                                        <div className="textBox">
                                            {single.name}
                                            <span>
                                                <i className="far fa-rupee-sign" />
                                                {numberWithCommas(single.presentValue)}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <i className="fal fa-clock" />
                                        <div className="textBox">
                                            Duration
                                            <span>{single.target} Yrs</span>
                                        </div>
                                    </div>
                                </div>
                                <hr
                                    className="H"
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                />
                                <div className="goalBox">
                                    <div>
                                        <div className="textBox">
                                            <div className="textBoxTwo">
                                                <span>SIP Required</span>
                                            </div>
                                            <div
                                                className="main-btnOne mt-10"
                                                style={{ width: '215px' }}
                                            >
                                                <i
                                                    className="far fa-rupee-sign text-white"
                                                    style={{
                                                        fontSize: '15px',
                                                        marginRight: '7px',
                                                    }}
                                                />
                                                {numberWithCommas(
                                                    calculateSip(
                                                        single.presentValue,
                                                        single.ValueOn,
                                                        single.target,
                                                        single.Inflation,
                                                        single.ROI
                                                    )
                                                )}
                                                &nbsp;/ Month
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="textBox d-flex mt-lg-5 mt-3">
                                            <div className="main-btn d-none skip">
                                                Inflation*
                                                <b>{single.Inflation} %</b>
                                            </div>
                                            <div className="main-btn skip mr-0">
                                                ROI*
                                                <b>{single.ROI} %</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-40 mb-30 d-none" />
                                <div className="goalBox2  d-none">
                                    <p className="text-center">
                                        Calculate % of goal to achieved from SIP
                                    </p>
                                    <div className="boxTwo">
                                        <div className="circle">100%</div>
                                        <div className="circleText">
                                            <p>
                                                <i className="textBg"></i>% of goal to achieved from
                                                SIP
                                            </p>
                                            <p>
                                                <i className="textBg grey"></i>
                                                Goal amount you already have
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="goalBox2 mt-30  d-none">
                                    <p className="mb-4">Alerts:</p>
                                    <ul style={{ fontSize: '14px' }}>
                                        <li>
                                            You can reduce present value if you already have some
                                            savings set out for these goals.
                                        </li>
                                        <li>
                                            We have assumed the inflation based on historical
                                            inflation data of these goals. You can set your own
                                            inflation figure as well based on your understanding.
                                        </li>
                                        <li>
                                            We have assumed ROI based on past 10 years Nifty 100
                                            returns. This should change based on the asset
                                            allocation done for this goal. You can change assumed
                                            ROI as well based on assumed ROI.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div
                            className="dropDown-btn"
                            style={{
                                display: 'flex',
                                margin: 'auto',
                            }}
                        >
                            <button
                                type="button"
                                className="toggle-button"
                                onClick={() => {
                                    handleEditGolesList(
                                        run,
                                        'detailed',
                                        single.detailed === 1 ? 0 : 1
                                    );
                                    RefreshGolesList();
                                }}
                                style={{
                                    display: single.filled === 1 ? 'flex' : 'none',
                                    margin: 'auto',
                                    width: '80px',
                                    justifyContent: 'center',
                                    borderRadius: '8px',
                                    border: 'none',
                                }}
                            >
                                <i
                                    className={`fa fa-angle-${single.detailed === 1 ? 'up' : 'down'
                                        }`}
                                    style={{
                                        fontSize: '20px',
                                        border: 'none',
                                        color: 'grey',
                                    }}
                                />
                            </button>
                        </div>
                    </>
                ))}
                <div
                    className="innerDiv d-none"
                    style={{
                        display: Number(GettotalSip()) === 0 || GettotalSip() === '' ? 'none' : '',
                    }}
                >
                    <h6 className="mb-2">
                        Total SIP value for your Goals : ₹{numberWithCommas(GettotalSip())}
                    </h6>
                </div>
                <div
                    className={Number(isEmbed) === 1 ? 'd-none' : 'mt-20 text-lg-right text-center'}
                >
                    <input
                        type="submit"
                        value="Back"
                        onClick={() => {
                            onButtonClick('pagetwo');
                            HandleArrayForm('GolesList', GolesList);
                        }}
                        className="main-btn skip mb-20"
                    />
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => {
                            onButtonClick('pagefour');
                            HandleArrayForm('GolesList', GolesList);
                            // window.history.pushState(null, '', `?steps=current-investments`);
                        }}
                        className="main-btn mb-20"
                    />
                </div>
            </form>
        </main>
    );
}

export default PageThree;
