import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import { BASEAPIURL } from '../../config';

function Information({ className }) {
    const { InfoTitle } = useParams();
    const [PageTitle, setPageTitle] = useState('Page');
    const [Page, setPage] = useState({ pagecontent: '', pagetitle: '' });

    useEffect(() => {
        let forPage = 'termandconditions';
        switch (InfoTitle) {
            case 'term-and-conditions':
                forPage = 'termandconditions';
                break;
            case 'privacy-policy':
                forPage = 'privacy';
                break;
            default:
                forPage = InfoTitle;
                break;
        }
        const FetchData = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/master/getpage`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        pagename: forPage,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    // setInfoData(resJson.data);
                    setPage(resJson.data);
                    // alert(resJson.data.pagename);
                    if (resJson.data.pagename === 'termandconditions') {
                        // document.title = 'Terms & Conditions';
                        setPageTitle('Terms & Conditions');
                    }
                    if (resJson.data.pagename === 'tariffRates') {
                        document.title = 'Tariff Rates';
                        setPageTitle('Tariff Rates');
                        // setPageTitle(resJson.data.pagetitle);
                    }
                    if (resJson.data.pagename === 'privacy') {
                        // document.title = 'Privacy Policy';
                        setPageTitle('Privacy Policy');
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchData();
    }, [InfoTitle, setPage, setPageTitle]);
    return (
        <>
            <section className={`appie-about-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content pr0">
                                <h2 className="title text-center mb-5">{PageTitle}</h2>
                                <div className="message-box">
                                    <div className="InformationPage">
                                        {Parser(Page.pagecontent)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Information;
