import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Parser from 'html-react-parser';
import Slider from 'react-slick';
import { BASEAPIURL } from '../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import team1 from '../../assets/images/team-img1.jpg';

function TeamBlock({ className }) {
    const sliderRef = useRef();
    const teamslider = {
        autoplay: true,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        // centerMode: true,
        // centerPadding: '0px',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const [Team, setTeam] = useState([]);

    const FetchFaqs = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/teammember/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                    SortStatus: 1,
                }),
            });
            console.log(res);
            const resJson = await res.json();
            if (res.status === 200) {
                setTeam(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFaqs();
    }, []);

    return (
        <>
            <Helmet>
                <title>Meet Our Expert Team - My Money Panda Financial Advisors</title>
                <meta
                    name="description"
                    content="Discover the skilled team behind My Money Panda. Learn about our financial experts dedicated to helping you make smarter money decisions."
                />
                <meta
                    name="keywords"
                    content="My Moneypanda"
                />
            </Helmet>
            <section className={`appie-testimonial-area ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                {/* <span>Meet the faces behind My Money Panda</span> */}
                                {/* <h3 className="appie-title">We are here for you</h3> */}
                                <h3 className="appie-title">We put your money to work</h3>
                                {/* <p>We look forward to meeting you</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-20">
                        <div className="col-lg-12">
                            <div className="testimonial-slider">
                                <Slider ref={sliderRef} {...teamslider}>
                                    {Team.map((singi) => (
                                        <div className="teamSlide">
                                            <div className="slider-item" key={singi.fagid}>
                                                <img
                                                    src={singi.image}
                                                    alt=""
                                                    style={{
                                                        // objectFit: 'cover',
                                                        width: '55%',
                                                    }}
                                                />
                                                <div className="content">
                                                    <div
                                                        className="row"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <h3>{Parser(singi.title)}</h3>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            fontSize: '18px',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <span>{Parser(singi.subtitle)}</span>
                                                    </div>
                                                    {/* <hr
                                                        className="mt-30 mb-30"
                                                        style={{
                                                            borderBottom: '3px solid #00EBB4',
                                                        }}
                                                    /> */}
                                                    {/* <p>{Parser(singi.subtitle)}</p> */}
                                                    <div
                                                        className="mt-10 mb-10"
                                                    >
                                                        <p>{Parser(singi.description)}</p>
                                                    </div>
                                                    <div
                                                        className="social"
                                                        style={{ display: singi.linkedin === '' ? 'none' : '' }}
                                                    >
                                                        <a href={singi.linkedin}>
                                                            <i className="fab fa-linkedin-in" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default TeamBlock;
