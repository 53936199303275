import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PageOne from './MultiStep/PageOne';
import PageTwo from './MultiStep/PageTwo';
import PageThree from './MultiStep/PageThree';
import PageFour from './MultiStep/PageFour';
import PageFive from './MultiStep/PageFive';
import PageUser from './MultiStep/PageUser';
import ProgressBar from './MultiStep/ProgressBar/ProgressBar';
import { BASEAPIURL, BASEURL } from '../../config';
import { DefaultGolesList } from '../administrator/pages/Dashboard/Investors/masterData';
import './ReviewInvest.css';

// const Inflation = 6;
// const ROI = 12;
let token = localStorage.getItem('token');
let jwttoken = localStorage.getItem('jwttoken');
let ReviewInvestmentPage = localStorage.getItem('ReviewInvestmentPage');
const ValidPage = ['pageone', 'pagetwo', 'pagethree', 'pagefour', 'pagefive'];
if (ReviewInvestmentPage && ValidPage.includes(ReviewInvestmentPage)) {
    // ReviewInvestmentPage = ReviewInvestmentPage;
} else {
    ReviewInvestmentPage = 'pageone';
}
// alert(ReviewInvestmentPage);

function ReviewInvest({ className, isEmbed, forPage }) {
    // for the embed purpose
    const [IsLoader, setIsLoader] = useState(false);
    const [HideOnEmbed, setHideOnEmbed] = useState(Number(isEmbed) === 1 ? 'd-none' : '');
    const [EmbedPage, setEmbedPage] = useState(forPage);
    // for the embed purpose
    const [ReviewInvtID, setReviewInvtID] = useState(0);
    const [isLogin, setisLogin] = useState(0);
    if (forPage && forPage === 'portfolio') {
        ReviewInvestmentPage = 'pagefour';
    }
    if (forPage && forPage === 'goles') {
        ReviewInvestmentPage = 'pagethree';
    }
    if (forPage && forPage === 'incomeandexpenses') {
        ReviewInvestmentPage = 'pagetwo';
    }
    // console.log(forPage);
    const [page, setPage] = useState(ReviewInvestmentPage);
    const [AjaxMsg, setAjaxMsg] = useState('');
    const [AjaxClass, setAjaxClass] = useState('d-none');
    const [FormData, setFormData] = useState({
        token,
        ReviewInvtID,
        jwttoken,
        ageGroup: 2,
        occupation: 1,
        reactinvstdown: 1,
        retprf: 1,
        monthlyIncome: '',
        perMonthSave: 5,
        income: '',
        CanInvest: localStorage.getItem('CanInvest'),
        ShouldInvest: localStorage.getItem('ShouldInvest'),
        WhereInvest: localStorage.getItem('WhereInvest'),
        ExpensesList: [],
        utm_source: localStorage.getItem('utm_source'),
        utm_medium: localStorage.getItem('utm_medium'),
        utm_campaign: localStorage.getItem('utm_campaign'),
    });

    const HandleForm = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
    };

    const HandleArrayForm = (name, value) => {
        setFormData({ ...FormData, [name]: value });
    };

    const nextPage = (pageNumber) => {
        // alert(pag)
        //  alert('1')
        // alert(pageNumber)
         if(pageNumber==='pagetwo')
            window.history.pushState(null, '', `?steps=income-expenses`);
        else if(pageNumber==='pagethree')
        window.history.pushState(null, '', `?steps=goal-planning`);
        else if(pageNumber==='pagefour')
        window.history.pushState(null, '', `?steps=current-investments`);
        else if(pageNumber==='pageuser')
        window.history.pushState(null, '', `?steps=profile-data`);
        console.log(pageNumber);
        setPage(pageNumber);
    };

    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case '1':
                setPage('pageone');
                break;
            case '2':
                setPage('pagetwo');
                break;
            case '3':
                setPage('pagethree');
                break;
            case '4':
                setPage('pagefour');
                break;
            case '5':
                setPage('pagefive');
                break;
            case '6':
                setPage('pageuser');
                break;
            default:
                setPage('pageone');
                break;
        }
    };
    const SubmitWorkersData = async () => {
        setIsLoader(true);
        try {
            const res = await fetch(`${BASEAPIURL}/users/reviewInvestment/submitData`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(FormData),
            });
            const resJson = await res.json();
            setIsLoader(false);
            if (res.status === 200) {
     

                if (resJson.status === 1) {
                    setAjaxClass('alert alert-success');
                    // window.location.href = `${BASEURL}Login`; // for redirecting after submit
                } else {
                    setAjaxClass('alert alert-success');
                }
                setAjaxMsg(resJson.message);
                if (resJson.rv_invt_id) {
                    localStorage.setItem('rv_invt_id', resJson.rv_invt_id);
                }
                if (resJson.isLogin === 1) {
                    setisLogin(1);
                    // nextPageNumber('5');
                } else {
                    setisLogin(0);
                    // nextPageNumber('5');
                }
                nextPageNumber('6');
                // window.location.href=`completion`
                
            } else {
                setAjaxMsg(resJson.message);
                setIsLoader(false);
            }
        } catch (err) {
            setAjaxMsg(err);
            setIsLoader(false);
        }
    };
    const Delete2ReviewRnvestment = async (forData, forId) => {
        try {
            const TheUrl = 'users/reviewInvestment/Delete2ReviewRnvestment';
            const res = await fetch(`${BASEAPIURL}/${TheUrl}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ forData, forId }),
            });
            const resJson = await res.json();
            console.log(resJson);
        } catch (err) {
            console.log(err);
        }
    };
    // for Goles List DefaultGolesList

    const [GolesList, setGolesList] = useState(DefaultGolesList);
    const handleAddGolesList = (index) => {
        // ExpensesTypeSelected.push(1);
        setGolesList((temp) => [
            ...temp,
            {
                index,
                name: 1,
                icon: '',
                img:'',
                selected: 0,
                presentValue: '',
                ValueOn: 0,
                target: '',
                detailed: 0,
            },
        ]);
    };
    const handleEditGolesList = (changeId, ind, val) => {
        GolesList[changeId][ind] = val;
    };
    const handleRemoveGolesList = (ind) => {
        if (ind !== 1) {
            setGolesList(GolesList.filter((item) => item.index !== ind));
        }
    };
    const RefreshGolesList = () => {
        const temp = Number(GolesList.length) + 10000;
        handleAddGolesList(temp);
        handleRemoveGolesList(temp);
    };
    // for Gloes List
    // for Multiple Expenses List
    const DefaultExpenses = [
        {
            index: 1,
            type: 1,
            subType: 0,
            amount: '',
        },
        {
            index: 2,
            type: 2,
            subType: 0,
            amount: '',
        },
        {
            index: 3,
            type: 3,
            subType: 0,
            amount: '',
        },
        {
            index: 4,
            type: 4,
            subType: 0,
            amount: '',
        },
    ];
    const [ExpensesList, setExpensesList] = useState(DefaultExpenses);

    const handleAddExpenses = (index) => {
        // ExpensesTypeSelected.push(1);
        setExpensesList((Expenses) => [
            ...Expenses,
            {
                index,
                type: 1,
                subType: '',
                amount: '',
            },
        ]);
    };
    const handleEditExpenses = (changeId, ind, val) => {
        ExpensesList[changeId][ind] = val;
    };
    const handleRemoveExpenses = (ind) => {
        if (ind !== 1) {
            setExpensesList(ExpensesList.filter((item) => item.index !== ind));
        }
    };
    const RefreshExpensesList = () => {
        const temp = Number(ExpensesList.length) + 10000;
        handleAddExpenses(temp);
        handleRemoveExpenses(temp);
    };
    // for Multiple Expenses List

    // for Multiple Expenses List
    const DefaultPortfolio = [
        {
            index: 1,
            type: 1,
            amount: '',
        },
        {
            index: 2,
            type: 2,
            amount: '',
        },
        {
            index: 3,
            type: 3,
            amount: '',
        },
        {
            index: 4,
            type: 4,
            amount: '',
        },
        {
            index: 5,
            type: 5,
            amount: '',
        },
    ];
    const [PortfolioList, setPortfolioList] = useState(DefaultPortfolio);

    const handleAddPortfolio = (index) => {
        // ExpensesTypeSelected.push(1);
        setPortfolioList((temp) => [
            ...temp,
            {
                index,
                type: 1,
                subType: '',
                amount: '',
            },
        ]);
    };
    const handleEditPortfolio = (changeId, ind, val) => {
        PortfolioList[changeId][ind] = val;
    };
    const handleRemovePortfolio = (ind) => {
        if (ind !== 1) {
            setPortfolioList(PortfolioList.filter((item) => item.index !== ind));
        }
    };
    const RefreshPortfolioList = () => {
        const temp = Number(PortfolioList.length) + 10000;
        handleAddPortfolio(temp);
        handleRemovePortfolio(temp);
    };
    // for Multiple Expenses List

    // for Getting the Existing Data
    const fetch4user = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/users/reviewInvestment/fetch4user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify({
                    token,
                    rv_invt_id: localStorage.getItem('rv_invt_id'),
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.isLogin && resJson.isLogin === 1) {
                    setisLogin(1);
                } else {
                    setisLogin(0);
                }
                if (resJson.isAlreadyFilled && resJson.isAlreadyFilled === 1) {
                    setReviewInvtID(resJson.rv_invt_id);
                    if (resJson.UserData.goles && resJson.UserData.goles.length > 0) {
                        setGolesList(resJson.UserData.goles);
                    }

                    if (resJson.UserData.portfolio && resJson.UserData.portfolio.length > 0) {
                        setPortfolioList(resJson.UserData.portfolio);
                    }

                    if (resJson.UserData.expenses && resJson.UserData.expenses.length > 0) {
                        setExpensesList(resJson.UserData.expenses);
                    }

                    setFormData({
                        token,
                        ReviewInvtID: resJson.rv_invt_id,
                        jwttoken,
                        ageGroup: resJson.UserData.rewiInvst.ageGroup,
                        occupation: resJson.UserData.rewiInvst.occupation,
                        reactinvstdown: 1,
                        retprf: 1,
                        userName: resJson.UserData.rewiInvst.userName,
                        userEmail: resJson.UserData.rewiInvst.userEmail,
                        userMobile: resJson.UserData.rewiInvst.userMobile,
                        monthlyIncome: resJson.UserData.rewiInvst.monthlyIncome,
                        perMonthSave: resJson.UserData.rewiInvst.perMonthSave,
                        income: '',
                        CanInvest: resJson.UserData.rewiInvst.CanInvest,
                        ShouldInvest: resJson.UserData.rewiInvst.ShouldInvest,
                        WhereInvest: resJson.UserData.rewiInvst.WhereInvest,
                        ExpensesList:
                            resJson.expenses && resJson.expenses.length > 0 ? resJson.expenses : [],
                        PortfolioList:
                            resJson.UserData.portfolio && resJson.UserData.portfolio.length > 0
                                ? resJson.UserData.portfolio
                                : [],
                        GolesList:
                            resJson.UserData.goles && resJson.UserData.goles.length > 0
                                ? resJson.UserData.goles
                                : [],
                    });
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    // for Getting the Existing Data

    useEffect(() => {
        token = localStorage.getItem('token');
        jwttoken = localStorage.getItem('jwttoken');
        localStorage.setItem('ReviewInvestmentPage', 'pageone');
        fetch4user();
        // for the embed purpose
        setHideOnEmbed(Number(isEmbed) === 1 ? 'd-none' : '');
        setEmbedPage(forPage);
        // for the embed purpose
    }, [forPage, isEmbed]);

    return (
        <>
            <section
                className={`review-area ${className || ''}`}
                style={{
                    background: Number(isEmbed) === 1 ? '#fff' : '',
                    minHeight: Number(isEmbed) === 1 ? 'auto' : '',
                    border: Number(isEmbed) === 1 ? '1px solid #f5f5f5' : '',
                }}
            >
                <div className="container">
                    <div className="row justify-content-end">
                        <div
                            className={Number(isEmbed) === 1 ? 'col-lg-12 mt-10' : 'col-lg-7 mt-10'}
                        >
                            <h5 className={HideOnEmbed} embedpage={EmbedPage}>
                                <NavLink to="/">
                                    <i className="fas fa-chevron-left" />
                                </NavLink>
                                Let's build your profile!
                            </h5>
                            <div className="innerBox" style={{ overflowX: 'hidden' }}>
                                <div className={Number(isEmbed) === 1 ? '' : 'progress-bg'}>
                                    <div className={HideOnEmbed}>
                                        <ProgressBar
                                            page={page}
                                            onPageNumberClick={nextPageNumber}
                                        />
                                    </div>
                                    {
                                        {
                                            pageone: (
                                                <PageOne
                                                    isEmbed={isEmbed}
                                                    onButtonClick={nextPage}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                />
                                            ),
                                            pagetwo: (
                                                <PageTwo
                                                    isEmbed={isEmbed}
                                                    Delete2ReviewRnvestment={
                                                        Delete2ReviewRnvestment
                                                    }
                                                    onButtonClick={nextPage}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                    HandleArrayForm={HandleArrayForm}
                                                    ExpensesList={ExpensesList}
                                                    AjaxClass={AjaxClass}
                                                    AjaxMsg={AjaxMsg}
                                                    handleAddExpenses={handleAddExpenses}
                                                    handleEditExpenses={handleEditExpenses}
                                                    RefreshExpensesList={RefreshExpensesList}
                                                    handleRemoveExpenses={handleRemoveExpenses}
                                                />
                                            ),
                                            pagethree: (
                                                <PageThree
                                                    isEmbed={isEmbed}
                                                    Delete2ReviewRnvestment={
                                                        Delete2ReviewRnvestment
                                                    }
                                                    onButtonClick={nextPage}
                                                    HandleArrayForm={HandleArrayForm}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                    GolesList={GolesList}
                                                    handleEditGolesList={handleEditGolesList}
                                                    RefreshGolesList={RefreshGolesList}
                                                />
                                            ),
                                            pagefour: (
                                                <PageFour
                                                    isEmbed={isEmbed}
                                                    Delete2ReviewRnvestment={
                                                        Delete2ReviewRnvestment
                                                    }
                                                    onButtonClick={nextPage}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                    SubmitWorkersData={SubmitWorkersData}
                                                    HandleArrayForm={HandleArrayForm}
                                                    PortfolioList={PortfolioList}
                                                    handleAddPortfolio={handleAddPortfolio}
                                                    handleEditPortfolio={handleEditPortfolio}
                                                    RefreshPortfolioList={RefreshPortfolioList}
                                                    handleRemovePortfolio={handleRemovePortfolio}
                                                />
                                            ),
                                            pagefive: (
                                                <PageFive
                                                    isEmbed={isEmbed}
                                                    onButtonClick={nextPage}
                                                    ReviewInvtID={ReviewInvtID}
                                                    isLogin={isLogin}
                                                    AjaxClass={AjaxClass}
                                                    AjaxMsg={AjaxMsg}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                />
                                            ),
                                            pageuser: (
                                                <PageUser
                                                    BASEURL={BASEURL}
                                                    isEmbed={isEmbed}
                                                    onButtonClick={nextPage}
                                                    HandleForm={HandleForm}
                                                    FormData={FormData}
                                                    AjaxClass={AjaxClass}
                                                    AjaxMsg={AjaxMsg}
                                                    SubmitWorkersData={SubmitWorkersData}
                                                    IsLoader={IsLoader}
                                                />
                                            ),
                                        }[page]
                                    }
                                    {/* {page} */}
                                    <div
                                        className={Number(isEmbed) === 1 ? 'text-center' : 'd-none'}
                                    >
                                        <input
                                            type="button"
                                            value="Submit"
                                            onClick={() => {
                                                if (forPage && forPage === 'portfolio') {
                                                    HandleArrayForm('PortfolioList', PortfolioList);
                                                }

                                                if (forPage && forPage === 'goles') {
                                                    HandleArrayForm('GolesList', GolesList);
                                                }
                                                if (forPage && forPage === 'incomeandexpenses') {
                                                    HandleArrayForm('ExpensesList', ExpensesList);
                                                }
                                                SubmitWorkersData();
                                            }}
                                            className="main-btn mb-20 mt-20"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ReviewInvest;
