import React from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
// import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
// import Footer from '../Helper/Footer';

import HeroSection from './HeroSection';
import SecondHeroSection from './SecondHeroSection';
import VideoSection from './VideoSection';
import ThirdHeroSection from './ThirdHeroSection';
import FourHeroSection from './FourHeroSection';
import FiveHeroSection from './FiveHeroSection';
import SixHeroSection from './SixHeroSection';
import FooterMutualFund from './FooterMutualFund';
import Footer from '../Helper/Footer';

function MutualFund(){
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
        <Helmet>
        <title>Mutual Funds Investment-Get Personalized Plans with MyMoneyPanda</title>
        <meta
          name="description"
          content="Unlock your financial potential with personalized mutual funds investments designed for your investment goals. Build your wealth with confidence with MyMoneyPanda."
        />
        <meta
          name="keywords"
          content="Mutual Funds Investment, Mutual Funds Investment Calculator, Equity Mutual Funds, Mutual Funds Investment Plans, Investment in Mutual Funds"
        />
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroSection/>
            <SecondHeroSection/>
            <VideoSection/>
            <ThirdHeroSection/>
            <FourHeroSection/>
            <FiveHeroSection/>
            <SixHeroSection/>
            <FooterMutualFund/>
            <Footer/>
        </>
    )
}

export default MutualFund;