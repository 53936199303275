import React, { useState } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEAPIURL, BASEURL } from '../../config';

const Token = localStorage.getItem('token');
const LSName = localStorage.getItem('fname');
const LSEmail = localStorage.getItem('email');

function ChangePassword() {
    const [lfVisibility, setlfVisibility] = useState('block');
    const [PasswordType, setPasswordType] = useState('password');
    const [drawer, drawerAction] = useToggle(false);
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState('');
    const [newpassword, setNewPassword] = useState([]);
    const [confirmpassword, setConfirmPassword] = useState([]);
    const [password, setPassword] = useState([]);

    function handleSubmit(e) {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        console.log(Token, password, newpassword, confirmpassword);
        const data = { token: Token, password, newpassword, confirmpassword };
        fetch(`${BASEAPIURL}/user/createNewPassword`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
            },
            body: JSON.stringify(data),
        }).then((result) => {
            result.json().then((resp) => {
                setMessage(Parser(`<div class="alert alert-success">${resp.message}</div>`));
            });
        });
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile-block">
                                <div className="leftBox">
                                    <img src={userImg1} alt="" />
                                    <h5>{LSName}</h5>
                                    <a href="#">
                                        <i className="far fa-envelope" />
                                        {LSEmail}
                                    </a>
                                    <hr />
                                    <div className="navBox">
                                        <NavLink exact to="/profile">
                                            <i className="far fa-user" />
                                            Basic Details
                                        </NavLink>
                                        {/* <NavLink exact to="/ContactDetail">
                                            <i className="far fa-address-book" />
                                            &nbsp;Contact Details
                                        </NavLink>
                                        <NavLink exact to="/BankDetail">
                                            <i className="far fa-landmark"></i>
                                            Bank Details
                                        </NavLink>
                                        <NavLink exact to="/nominee">
                                            <i className="far fa-user-plus" />
                                            Nominee Details
                                        </NavLink> */}
                                        <NavLink exact to="/change-password">
                                            <i className="far fa-lock" />
                                            Change Password
                                        </NavLink>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                localStorage.removeItem('jwttoken');
                                                window.location.href = BASEURL;
                                            }}
                                        >
                                            <div className="iconBox">
                                                <i className="fal fa-sign-out" />
                                            </div>
                                            <div>Logout</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <hr className="d-lg-none d-block mt-50 mb-50" />
                                <div className="rightBox">
                                    <h6>Reset Password</h6>
                                    <form
                                        method="POST"
                                        onSubmit={handleSubmit}
                                        style={{ display: lfVisibility }}
                                    >
                                        <div className="form-area mb-20">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type={PasswordType}
                                                            name="oldPassword"
                                                            // value={user.password}
                                                            placeholder="Enter Old Password"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Old Password</label>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                right: '20px',
                                                                top: '22px',
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-eye"
                                                                onClick={() =>
                                                                    setPasswordType('text')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'text'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-eye-slash"
                                                                onClick={() =>
                                                                    setPasswordType('password')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'password'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="#"
                                                                className="text-dark"
                                                                onClick={() => {
                                                                    setlfVisibility('none');
                                                                }}
                                                            >
                                                                <p></p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div
                                                        className="view-password d-flex"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type={PasswordType}
                                                            name="confirmpassword"
                                                            placeholder="Enter New Password"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Enter New Password</label>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '20px',
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-eye"
                                                                onClick={() =>
                                                                    setPasswordType('text')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'text'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-eye-slash"
                                                                onClick={() =>
                                                                    setPasswordType('password')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'password'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <a
                                                            href="#"
                                                            className="text-dark"
                                                            onClick={() => {
                                                                setlfVisibility('none');
                                                            }}
                                                        >
                                                            <p></p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-box">
                                                        <input
                                                            type={PasswordType}
                                                            name="confirmpassword"
                                                            placeholder=" Confirm Password "
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label> Confirm Password </label>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                right: '20px',
                                                                top: '22px',
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-eye"
                                                                onClick={() =>
                                                                    setPasswordType('text')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'text'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-eye-slash"
                                                                onClick={() =>
                                                                    setPasswordType('password')
                                                                }
                                                                style={{
                                                                    display:
                                                                        PasswordType === 'password'
                                                                            ? 'none'
                                                                            : '',
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <a
                                                            href="#"
                                                            className="text-dark"
                                                            onClick={() => {
                                                                setlfVisibility('none');
                                                            }}
                                                        >
                                                            <p></p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-12"
                                                    style={{ clear: 'both' }}
                                                >
                                                    <div style={styleMSG}>{message}</div>
                                                </div>
                                                <div className="col-lg-12 mt-20 text-lg-left text-center">
                                                    <input
                                                        type="submit"
                                                        name="submit"
                                                        value="Save"
                                                    />
                                                    <input
                                                        type="submit"
                                                        name="submit"
                                                        value="Cancel"
                                                        className="cancel"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <BackToTop />
        </>
    );
}

export default ChangePassword;
