import React from "react";
import moneycuate from "../../assets/images/mutualfundimg/Manage money-cuate 1.svg";
import './css/thirdherosection.css'

function ThirdHeroSection(){
    return <>
    <div className="thhs-main">
        
        <div className="thhs-container">
            <p className="thhs-subtitle">Real time Analytics</p>
            <h2 className="thhs-title">Our all - CAP BASKET </h2>
            <div className="thhs-image-container">
                <img src={moneycuate} alt="moneycuate" className="thhs-image"/>
            </div>
            <p className="thhs-description">Step up your investment game with our All-Cap Basket. Handpicked mutual funds aimed for strong returns to help your money grow steadily. Make your money work for you with My Money Panda.</p>
            <a href="https://invest.mymoneypanda.in/" className="thhs-link">
                <div className="thhs-button">
                    Explore
                </div>
            </a>
            
        </div>
    </div>
    </>
}

export default ThirdHeroSection;
