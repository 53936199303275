/* eslint-disable no-shadow */
// import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Helper/Footer';
import Header from '../Helper/HeaderMain';
import Drawer from '../Mobile/Drawer';
import GoalCalculator from "./GoalCalculator"



export default function CalCulator() {
    const { type } = useParams();
    console.log("🚀 ~ file: index.js:17 ~ CalCulator ~ type:", type)
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <div className='calculator_component'>
              <GoalCalculator />    
            </div>
            <Footer />
            <BackToTop />
        </>
    )
}