import React from 'react';
import './css/videosection.css'

function VideoSection(){
    const url = 'https://www.youtube.com/embed/Ku4srcYslEI';

    return <>
        <div className='video-main'>
        <p id='videofirstheading'>
        Your key to financial freedom is here 
        </p>
        <p id='videosecondheading'>
        Our services and advantages
        </p>
        <div className='video-container'>
            <div className='videodiv' >
                <iframe title="Mutual Fund" src={url} style={{ width:'100%', height:'100%'}} />
            </div>
        </div>
    </div>
    </>
}

export default VideoSection;
