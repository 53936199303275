import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Logo.jpeg';

const ProfileMenu = React.lazy(() => import('../administrator/pages/Dashboard/ProfileMenu'));

function Drawer({ drawer, action }) {
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" id="closedrawer" onClick={(e) => action(e)}>
                                        <i className="far fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ProfileMenu isDrawer={1} forpage="DraverAdmin" />
                                    </Suspense>
                                    <ul className="offcanvas_main_menu d-none">
                                        <li>
                                            <Link to="/exam-preparation">Exam Preparation</Link>
                                        </li>
                                        <li>
                                            <Link to="/skill-learn">Skill Learn</Link>
                                        </li>
                                        <li>
                                            <Link to="/university-courses">University Courses</Link>
                                        </li>
                                        <li>
                                            <Link to="/free-courses">Free Courses</Link>
                                        </li>
                                        <li>
                                            <Link to="/docsta-academy">DocSta Academy</Link>
                                        </li>
                                        <li>
                                            <Link to="/premium-plans">Premium Plans</Link>
                                        </li>
                                        <li>
                                            <Link to="/team-learning">Team Learning</Link>
                                        </li>
                                        <li>
                                            <Link to="/career-guide">Career Guide</Link>
                                        </li>
                                        <li>
                                            <Link to="/deals">Deals</Link>
                                        </li>
                                        <li>
                                            <Link to="/favourites">Favourites</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-dribbble" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-support">
                                    <a href="#">
                                        <i className="far fa-phone" />
                                        +91 99201 12179, 89795 23872
                                    </a>
                                    <a href="#">
                                        <i className="far fa-envelope" />
                                        hello@wealthynivesh.in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
