import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import Slider from 'react-slick';
import { Card, CardGroup, Button, Accordion } from 'react-bootstrap';
import { BASEURL } from '../../config';
import '../ReviewInvest/ReviewInvest.css';
import GoalTracker from './GoalTracker';
import IncomeExpensenses from './IncomeExpensenses';

const image1 = '/images/baskets/image1.png';
const image2 = '/images/baskets/image2.png';
const image3 = '/images/baskets/image3.png';

// const data = [
//     ['Year', '3years return', ''],
//     ['2019', 1000, 200],
//     ['2020', 2000, 400],
//     ['2021', 2500, 300],
//     ['2022', 2800, 600],
//     ['2023', 3200, 500],
// ];

// export const options = {
//     title: 'Investment Performance',
//     curveType: 'function',
//     legend: { position: 'bottom' },
//     colors: ['#00EBB4', '#3D52ED'],
//     lineWidth: 5,
// };

export const dataBalanced = [
    ['day', 'a', 'b', 'c', 'd'],
    ['', 0, 0, 0, 0],
    ['WN Balanced', 0, 0, 16.59, 20],
    ['', 0, 0, 0, 0],
    ['BSE 500', 0, 12.63, 0, 18],
    ['', 0, 0, 0, 0],
    ['Alpha return', 10, 12.63, 16.59, 20],
];
export const dataPioneer = [
    ['day', 'a', 'b', 'c', 'd'],
    ['', 0, 0, 0, 0],
    ['WN Pioneer', 0, 0, 26.66, 30],
    ['', 0, 0, 0, 0],
    ['BSE 500', 0, 14.3, 0, 18],
    ['', 0, 0, 0, 0],
    ['Alpha return', 10, 14.3, 26.66, 30],
];
export const dataBull = [
    ['day', 'a', 'b', 'c', 'd'],
    ['', 0, 0, 0, 0],
    ['WN Bull', 0, 0, 32.09, 35],
    ['', 0, 0, 0, 0],
    ['BSE 500', 0, 22, 0, 28],
    ['', 0, 0, 0, 0],
    ['Alpha return', 10, 14.3, 32.09, 35],
];

export const options = {
    legend: 'none',
    candlestick: {
        fallingColor: { strokeWidth: 0, fill: '#3D52ED' }, // red
        risingColor: { strokeWidth: 0, fill: '#00EBB4' }, // green
    },
};
export const data1 = [
    ['Fund', 'Percentage'],
    ['Equity Fund', 65],
    ['Debt Fund', 35],
];

export const options1 = {
    title: 'Asset Category',
    pieHole: 0.5,
    is3D: false,
    colors: ['#00EBB4', '#3D52ED'],
};

export const data1a = [
    ['Fund', 'Percentage'],
    ['Large Cap Index Funds', 30],
    ['Active Mid & Small Cap Funds', 40],
    ['Fixed Income Funds', 30],
];

export const options1a = {
    title: 'Equity Fund',
    pieHole: 0.4,
    is3D: false,
    colors: ['#00EBB4', '#7CF2D6', '#C4F6EA'],
};

export const data1b = [
    ['Fund', 'Percentage'],
    ['Corporate Bond Fund', 30],
    ['Liquid Fund', 40],
    ['Short Term Fund', 30],
];

export const options1b = {
    title: 'Debt Fund',
    pieHole: 0.4,
    is3D: false,
    colors: ['#3D52ED', '#A7B1FF', '#D8DCFF'],
};

const BasketData = [
    {
        basketID: 1,
        img: image1,
        profile: 2, // Moderate
        title: 'WN PIONEER BASKET',
        alpha: 26.66,
        benchmark: 16.46,
        funds: [
            {
                name: 'Canara Robeco Bluechip Equity Fund',
                value: 20,
            },
            {
                name: 'SBI Bluechip Fund',
                value: 15,
            },
            {
                name: 'PGIM INDIA Midcap Opp. Fund',
                value: 20,
            },
            {
                name: 'Mirae Asset Midcap Fund',
                value: 15,
            },
            {
                name: 'Quant Small Cap Fund',
                value: 15,
            },
            {
                name: 'Edelweiss Small Cap Fund',
                value: 15,
            },
        ],
    },
    {
        basketID: 2,
        img: image2,
        // img: 'https://invest.wealthynivesh.in/app/img/demo/image4.png',
        profile: 3, // Aggressive
        title: 'WN BULL BASKET',
        alpha: 32.09,
        benchmark: 22.0,
        funds: [
            {
                name: 'Canara Robeco Bluechip Equity Fund',
                value: 15,
            },
            {
                name: 'Quant Small Cap Fund',
                value: 20,
            },
            {
                name: 'Edelweiss Small Cap Fund',
                value: 25,
            },
            {
                name: 'Mirae Asset Midcap Fund',
                value: 25,
            },
            {
                name: 'PGIM INDIA Midcap Opp. Fund',
                value: 15,
            },
        ],
    },
    {
        basketID: 3,
        img: image3,
        profile: 1, // Conservative
        title: 'WN BALANCED BASKET',
        alpha: 16.59,
        benchmark: 12.63,
        funds: [
            {
                name: 'TATA Balanced Advantage Fund',
                value: 20,
            },
            {
                name: 'Edelweiss Balanced Adv. Fund',
                value: 20,
            },
            {
                name: 'Baroda BNP P Bal Adv. Fund',
                value: 20,
            },
            {
                name: 'Kotak Equity Hybrid Fund',
                value: 20,
            },
            {
                name: 'Quant Absolute Fund',
                value: 20,
            },
        ],
    },
];

function Dashboard({ className, RewiInvsts }) {
    const myRef = useRef(null);
    const [GTSliderData, setGTSliderData] = useState([]);
    const [amountInvest, setamountInvest] = useState(localStorage.getItem('amountInvest'));
    const [Value1, setValue1] = useState(6);
    const handleChange = (event) => {
        setValue1(event.target.value);
    };
    const [value2, setValue2] = useState(12);
    const handleChangeROI = (event) => {
        setValue2(event.target.value);
    };
    const ProfileOption = useRef(null);
    const [showQues, setQues] = useState(0);
    const [GoalsCount, setGoalsCount] = useState(0);
    const [profileType, setprofileType] = useState(
        localStorage.getItem('profileType') === null ? 2 : localStorage.getItem('profileType')
    );

    const openQuestion = (value) => {
        setQues(value);
    };

    // function for Chart

    const [datachart, setDataChart] = useState(dataBull);

    const handleChangeChart = () => {
        let newData;
        if (datachart === dataPioneer) {
            newData = dataBull;
        } else if (datachart === dataBull) {
            newData = dataBalanced;
        } else {
            newData = dataPioneer;
        }
        // const newData = datachart === dataBalanced ? dataPioneer : dataBalanced;
        setDataChart(newData);
    };

    // Function for Accordian //
    const [isOpen, setIsOpen] = useState(true);
    const [isOpenSecond, setIsOpenSecond] = useState(true);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const toggleAccordionSecond = () => {
        setIsOpenSecond(!isOpenSecond);
    };

    // slider content

    const sliderRef = useRef();
    const teamslider = {
        autoplay: true,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    function numberWithCommas(x) {
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let ReturnValue = '';
        if (x && x !== '' && x !== null) {
            ReturnValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // ReturnValue = 'saf';
        } else {
            ReturnValue = '';
        }
        return ReturnValue;
    }
    function GetPMT(ir, np, pv, fv) {
        // https://stackoverflow.com/questions/5294074/pmt-function-in-javascript
        let pmt = 0;
        let temp = 0;
        let pvif = 0;
        let typeHere = 0;

        typeHere = 0;

        if (ir === 0) return -(pv + fv) / np;

        pvif = (1 + ir) ** np;
        pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

        if (typeHere === 1) {
            temp = 1 + ir;
            pmt /= temp;
        }
        pmt = pmt.toFixed(0);
        // pmt - number
        return pmt * -1;
    }

    function calculateCompoundValue(principal, rate, time) {
        /* Calculate compound interest */
        if (principal !== '') {
            console.log(`principal: ${principal}`);
            console.log(`rate: ${rate}`);
            console.log(`time: ${time}`);
            const temp = 1 + Number(rate) / 100;
            const futureValue = Number(principal) * temp ** Number(time);
            console.log(`futureValue: ${futureValue}`);
            return futureValue;
        }
        return 0;
    }

    function calculateSip(prstval, prstvalIn, Target, Inflation, ROI) {
        const PresentValue = Number(prstval);
        console.log(prstvalIn);
        const FutureValue = calculateCompoundValue(PresentValue, Inflation, Number(Target));
        // ratePerPeriod, numberOfPayments, presentValue, futureValue, type
        const SipFutureValue = GetPMT(ROI / 100 / 12, Number(Target) * 12, 0, FutureValue, 0);
        console.log(SipFutureValue);
        return SipFutureValue;
    }
    useEffect(() => {
        if (RewiInvsts.goles) {
            setGoalsCount(RewiInvsts.goles.length);
            const temp = [];
            RewiInvsts.goles.map((s) => {
                if (s.filled === 1) {
                    temp.push(s);
                }
                return '';
            });
            setGTSliderData(temp);
        }
        if (localStorage.getItem('explore') === '1') {
            setTimeout(() => {
                localStorage.setItem('explore', 1);
                myRef.current.scrollIntoView();
            }, 3000);
        }
    }, [RewiInvsts]);
    return (
        <>
            <section className={`admin-user-section ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dashboard-head" style={{ marginBottom: '0px' }}>
                                <div className="iconBox">
                                    <i className="fal fa-info-circle" />
                                </div>
                                <div className="textBox">
                                    <p>
                                        Hello {localStorage.getItem('fname')}, Your portfolio
                                        curated based on your investment profile
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 d-none">
                        <div className="col-lg-12">
                            <div className="blockTwo">
                                <h2>Asset Analysis</h2>
                                <div className="chartBlock row">
                                    <div className="col-lg-12 p-0">
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="400px"
                                            data={data1}
                                            options={options1}
                                        />
                                    </div>
                                    <div className="col-lg-6 p-0">
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="400px"
                                            data={data1a}
                                            options={options1a}
                                        />
                                    </div>
                                    <div className="col-lg-6 p-0">
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="400px"
                                            data={data1b}
                                            options={options1b}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" ref={ProfileOption}>
                        <div className="col-lg-12">
                            <div className="blockTwo">
                                <div
                                    className="accrodion-grp user-accrodion"
                                    data-grp-name="faq-accrodion"
                                >
                                    {/* Goles Display will be here */}
                                    <div
                                        onClick={() => {
                                            // openQuestion(2);
                                            // ProfileOption.current.scrollIntoView();
                                        }}
                                        className={`accrodion
                                            ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="mb-3">
                                            <div className="accrodion-title-goal">
                                                <div>
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                            marginTop: '10px',
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <Link
                                                            // to="/review-investment"
                                                            to="/review"
                                                            target="_BLANK"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => {
                                                                localStorage.setItem(
                                                                    'ReviewInvestmentPage',
                                                                    'pagethree'
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                            Add New Goal
                                                        </Link>
                                                    </div>
                                                    <h4 style={{ fontWeight: 700 }}>
                                                        Goals Tracker
                                                    </h4>
                                                    <p
                                                        style={{
                                                            color: '#717171',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        Consistently track progress towards your
                                                        goals to increase the likelyhood of
                                                        achieving them.
                                                    </p>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display:
                                                        RewiInvsts.goles &&
                                                            RewiInvsts.goles.length > 0
                                                            ? ''
                                                            : 'none',
                                                }}
                                            >
                                                <GoalTracker
                                                    numberWithCommas={numberWithCommas}
                                                    calculateSip={calculateSip}
                                                    dataValue={GTSliderData}
                                                    dataValue1={
                                                        RewiInvsts.goles &&
                                                            RewiInvsts.goles.length > 0
                                                            ? RewiInvsts.goles
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{ padding: '30px 50px' }}
                                            >
                                                <div className="inner mb-0">
                                                    <div
                                                        className="review-area"
                                                        style={{
                                                            background: 'transparent',
                                                            display: 'block',
                                                            minHeight: 'auto',
                                                        }}
                                                    >
                                                        <div
                                                            className="innerBox mt-0"
                                                            style={{
                                                                maxHeight: 'max-content',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            <div
                                                                className="alert alert-warning text-center"
                                                                style={{
                                                                    display:
                                                                        GoalsCount === 0
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                There is no Goals Set yet.
                                                            </div>
                                                            <div
                                                                className="text-center"
                                                                style={{
                                                                    display:
                                                                        GoalsCount === 0
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                <a
                                                                    // href={`${BASEURL}review-investment`}
                                                                    href={`${BASEURL}review`}
                                                                    className="btn btn-info"
                                                                >
                                                                    Review Investment Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Goles Display will be here */}

                                    {/* Income and Expenses Display will be here */}
                                    <div
                                        className={`accrodion
                                            ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="mb-3">
                                            <div className="accrodion-title-goal">
                                                <div>
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                            marginTop: '10px',
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <Link
                                                            // to="/review-investment"
                                                            to="/review"
                                                            target="_BLANK"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => {
                                                                localStorage.setItem(
                                                                    'ReviewInvestmentPage',
                                                                    'pagetwo'
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                            &nbsp;Edit
                                                        </Link>
                                                    </div>
                                                    <h4 style={{ fontWeight: 700 }}>
                                                        Income & Expenses
                                                    </h4>
                                                    <p
                                                        style={{
                                                            color: '#717171',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        Calculate monthly income and expenses, and
                                                        determine the monthly investible surplus
                                                    </p>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display:
                                                            RewiInvsts.goles &&
                                                                RewiInvsts.goles.length > 0
                                                                ? ''
                                                                : 'none',
                                                    }}
                                                >
                                                    <IncomeExpensenses
                                                        // numberWithCommas={numberWithCommas}
                                                        expensesValue={
                                                            RewiInvsts.expenses &&
                                                                RewiInvsts.expenses.length > 0
                                                                ? RewiInvsts.expenses
                                                                : []
                                                        }
                                                        rewiInvst={
                                                            RewiInvsts.rewiInvst &&
                                                                RewiInvsts.rewiInvst.rv_invt_id
                                                                ? RewiInvsts.rewiInvst
                                                                : {}
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{ padding: '30px 50px' }}
                                            >
                                                <div className="inner mb-0">
                                                    <div
                                                        className="review-area"
                                                        style={{
                                                            background: 'transparent',
                                                            display: 'block',
                                                            minHeight: 'auto',
                                                        }}
                                                    >
                                                        <div
                                                            className="innerBox mt-0"
                                                            style={{
                                                                maxHeight: 'max-content',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            <div
                                                                className="alert alert-warning text-center"
                                                                style={{
                                                                    display:
                                                                        GoalsCount === 0
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                There is no Goals Set yet.
                                                            </div>
                                                            <div
                                                                className="text-center"
                                                                style={{
                                                                    display:
                                                                        GoalsCount === 0
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                <a
                                                                    // href={`${BASEURL}review-investment`}
                                                                    href={`${BASEURL}review`}
                                                                    className="btn btn-info"
                                                                >
                                                                    Review Investment Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-4">
                                            <div className="chart-box-left">
                                                <div className="boxOneNew">
                                                    <h1>
                                                        {BasketData.map((s) => (
                                                            <small
                                                                style={{
                                                                    fontWeight: 700,
                                                                    color: '#000',
                                                                    display:
                                                                        Number(profileType) ===
                                                                            Number(s.profile)
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                {s.alpha}%
                                                            </small>
                                                        ))}
                                                        <span>P.A.</span>
                                                    </h1>
                                                    <p>Last 3 Years Returns</p>
                                                    {/* <small
                                                        style={{
                                                            display: 'block',
                                                            color: 'black',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        Benchmark:&nbsp;
                                                        {BasketData.map((s) => (
                                                            <ry
                                                                style={{
                                                                    display:
                                                                        Number(profileType) ===
                                                                        Number(s.profile)
                                                                            ? ''
                                                                            : 'none',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {s.benchmark}%
                                                            </ry>
                                                        ))}
                                                    </small> */}
                                                </div>
                                                <h6 className="mt-30">Portfolio Basket</h6>
                                                <div className="selectdiv">
                                                    <select
                                                        value={profileType}
                                                        name="profileType"
                                                        onChangeCapture={(e) => {
                                                            setprofileType(e.target.value);
                                                            localStorage.setItem(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                            handleChangeChart();
                                                        }}
                                                    >
                                                        <option value="3">WN BULL BASKET</option>
                                                        <option value="2">WN PIONEER BASKET</option>
                                                        <option value="1">
                                                            WN BALANCED BASKET
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="amountdiv">
                                                    <input
                                                        value={localStorage.getItem('amountInvest')}
                                                        name="amountInvest"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            localStorage.setItem(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                            setamountInvest(e.target.value);
                                                        }}
                                                    />
                                                    <span
                                                        className="mt-10"
                                                        style={{
                                                            color: 'white',
                                                            display: 'flex',
                                                            fontSize: '18px',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        Start Investing
                                                    </span>
                                                </div>
                                                <h6 className="pt-10 d-none">Asset Allocation</h6>
                                                <div className=" amountdiv d-none align-items-center justify-content-between">
                                                    <div>
                                                        <span>Equity</span>
                                                        <span style={{ float: 'right' }}>
                                                            {Value1}%
                                                        </span>
                                                    </div>
                                                    <div className="seek-bar-container2 d-none">
                                                        <input
                                                            type="range"
                                                            min="0"
                                                            max="100"
                                                            value={Value1}
                                                            onChange={handleChange}
                                                            className="seek-bar2"
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" amountdiv d-none align-items-center justify-content-between">
                                                    <div>
                                                        <span>Debt</span>
                                                        <span style={{ float: 'right' }}>
                                                            {value2}%
                                                        </span>
                                                    </div>
                                                    <div className="seek-bar-container2">
                                                        <input
                                                            type="range"
                                                            min="0"
                                                            max="100"
                                                            value={value2}
                                                            onChange={handleChangeROI}
                                                            className="seek-bar2"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className=" amountdiv d-flexa align-items-center justify-content-between">
                                                    <b>30%</b>
                                                    Equity
                                                </div>
                                                <div className=" amountdiv d-flexa align-items-center justify-content-between">
                                                    <b>30%</b>
                                                    Debt
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="chart-box-right">
                                                <div className="headBox">
                                                    <div className="boxOne d-none">
                                                        <h1>
                                                            {BasketData.map((s) => (
                                                                <small
                                                                    style={{
                                                                        fontWeight: 700,
                                                                        color: '#f5f5f5',
                                                                        display:
                                                                            Number(profileType) ===
                                                                                Number(s.profile)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    {s.alpha}%
                                                                </small>
                                                            ))}
                                                            <span>P.A.</span>
                                                        </h1>
                                                        <p>Last 3 Years Returns</p>
                                                        <small
                                                            style={{
                                                                display: 'block',
                                                                color: '#f5f5f5',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            Benchmark:&nbsp;
                                                            {BasketData.map((s) => (
                                                                <ry
                                                                    style={{
                                                                        display:
                                                                            Number(profileType) ===
                                                                                Number(s.profile)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    {s.benchmark}%
                                                                </ry>
                                                            ))}
                                                        </small>
                                                    </div>
                                                    <div className="boxThree">
                                                        {BasketData.map((s) => (
                                                            <span
                                                                style={{
                                                                    // fontWeight: 700,
                                                                    // color: '#f5f5f5',
                                                                    display:
                                                                        Number(profileType) ===
                                                                            Number(s.profile)
                                                                            ? ''
                                                                            : 'none',
                                                                }}
                                                            >
                                                                {s.title}
                                                            </span>
                                                        ))}
                                                    </div>
                                                    <div className="boxFour">
                                                        {BasketData.map((s) => (
                                                            <div className="delta">
                                                                <span
                                                                    style={{
                                                                        // fontWeight: 700,
                                                                        // color: '#f5f5f5',
                                                                        display:
                                                                            Number(profileType) ===
                                                                                Number(s.profile)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-circle"
                                                                        style={{
                                                                            margin: '5px',
                                                                            color: '#00EBB4',
                                                                        }}
                                                                    />
                                                                    {s.title}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="boxFive">
                                                        <span>
                                                            <i
                                                                className="fa fa-circle"
                                                                style={{
                                                                    margin: '5px',
                                                                    color: '#3D52ED',
                                                                }}
                                                            />
                                                            BSE 500
                                                        </span>
                                                    </div>
                                                    <div className="boxTwoNew">
                                                        <a
                                                            href="https://invest.wealthynivesh.in/"
                                                            className="main-btn"
                                                            target="_BLANK"
                                                            rel="noreferrer"
                                                        >
                                                            Invest Now
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <div className=" d-flex chartBox mt-30">
                                                    <Chart
                                                        chartType="LineChart"
                                                        width="100%"
                                                        height="330px"
                                                        data={data}
                                                        options={options}
                                                    />
                                                </div> */}
                                                {BasketData.map((s) => (
                                                    <div className=" d-flex chartBox mt-30">
                                                        <Chart
                                                            chartType="CandlestickChart"
                                                            width="100%"
                                                            height="330px"
                                                            style={{
                                                                display:
                                                                    Number(profileType) ===
                                                                        Number(s.profile)
                                                                        ? ''
                                                                        : 'none',
                                                            }}
                                                            data={datachart}
                                                            options={options}
                                                        //     // eslint-disable-next-line no-nested-ternary
                                                        //     profileType === 1
                                                        //     ? dataBull
                                                        //     : profileType === 2
                                                        //     ? dataPioneer
                                                        //     : dataBalanced
                                                        //      // eslint-enable-next-line no-nested-ternary
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row d-none mb-3">
                                        <div className="col-lg-12">
                                            <div className="blockTwo">
                                                <h2>₹ 1Lac invested for 5 years could have been</h2>
                                                <div className="innerBox">
                                                    <div className="boxOne">
                                                        <div className="chartBox">
                                                            <Chart
                                                                chartType="Bar"
                                                                width="100%"
                                                                height="200px"
                                                                data={dataBull}
                                                                options={options}
                                                            />
                                                        </div>
                                                        <p>WN Portfolio</p>
                                                        <h3>₹3,07,755</h3>
                                                    </div>
                                                    <div className="boxOne gray">
                                                        <div className="chartBox">
                                                            <Chart
                                                                chartType="Bar"
                                                                width="100%"
                                                                height="200px"
                                                                data={dataBull}
                                                                options={options}
                                                            />
                                                        </div>
                                                        <p>Benchmark</p>
                                                        <h3>₹2,22,637</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div
                                        ref={myRef}
                                        // onClick={() => {
                                        //     openQuestion(1);
                                        //     // ProfileOption.current.scrollIntoView();
                                        // }}
                                        className={`accrodion
                                            ${showQues === 1 ? 'active' : 'active'}`}
                                    >
                                        <div className="accrodion-inner mt-3">
                                            <div
                                                className="accrodion-title d-flex"
                                                onClick={toggleAccordion}
                                            >
                                                <h4>
                                                    See Fund Details
                                                    <i className="fal fa-info-circle" />
                                                </h4>
                                            </div>
                                            {isOpen && (
                                                <div
                                                    className="accordian-content"
                                                    style={{
                                                        display: showQues === 1 ? 'block' : 'block',
                                                    }}
                                                >
                                                    {BasketData.map((s) => (
                                                        <div
                                                            key={s.basketID}
                                                            className="inner"
                                                            style={{
                                                                display:
                                                                    Number(profileType) ===
                                                                        Number(s.profile) ? '' : 'none',
                                                            }}
                                                        >
                                                            <div className="container">
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        padding: '20px',
                                                                        font: '19px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    <div className="col-md-8">
                                                                        <span>{s.title}</span>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <span>Amount</span>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <span>Allocation %</span>
                                                                    </div>
                                                                </div>
                                                                {s.funds.map((f) => (
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            padding: '20px',
                                                                            fontSize: '17px',
                                                                            borderBottom:
                                                                                '.5px solid #e6e6e6',
                                                                        }}
                                                                    >
                                                                        <div className="col-lg-12 d-flex">
                                                                            <div
                                                                                className="col-lg-8"
                                                                                style={{
                                                                                    listStyleType:
                                                                                        'disc',
                                                                                    fontWeight:
                                                                                        '300',
                                                                                }}
                                                                            >
                                                                                <li>{f.name}</li>
                                                                            </div>
                                                                            <div
                                                                                className="col-lg-2"
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '700',
                                                                                    color: 'blue',
                                                                                }}
                                                                            >
                                                                                ₹&nbsp;
                                                                                {(Number(
                                                                                    amountInvest
                                                                                ) *
                                                                                    Number(
                                                                                        f.value
                                                                                    )) /
                                                                                    100}
                                                                            </div>
                                                                            <div
                                                                                className="col-lg-1"
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                    border: '2px solid blue',
                                                                                    borderRadius:
                                                                                        '4px',
                                                                                    background:
                                                                                        'blue',
                                                                                    color: 'white',
                                                                                    marginLeft:
                                                                                        '40px',
                                                                                    alignItems:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                {f.value}%
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div
                                                        className=" Bullets mt-30 mb-30 p-5"
                                                        style={{
                                                            background: '#ffffff',
                                                            border: '1px solid #00EBB4',
                                                            borderRadius: '30px',
                                                            padding: '20px',
                                                            margin: '40px',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            Disclaimer
                                                        </span>
                                                        <hr />
                                                        <ul>
                                                            <li>
                                                                Portfolio Performance is based on
                                                                based on back tested data as on
                                                                31.01.2023. Past Performance is not
                                                                indicative of future returns. Actual
                                                                return would vary based on asset
                                                                allocation, market cap allocation,
                                                                time invested, fund performance, and
                                                                market cycles.
                                                            </li>
                                                            <li>
                                                                Mutual Fund Investments are subject
                                                                to market risks. We recommend this
                                                                portfolio for investors with an
                                                                investment horizon of 3+ years.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion d-none
                                            ${showQues === 3 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>
                                                    Upload & Track External Portfolio
                                                    <i className="fal fa-info-circle" />
                                                </h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 3 ? 'block' : 'none',
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion
                                        ${showQues === 4 ? 'active' : 'active'}`}
                                    >
                                        <div className="accrodion-inner">
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <div
                                                        className="accrodion-title"
                                                        onClick={toggleAccordionSecond}
                                                    >
                                                        <h4>
                                                            Other Popular Investment Themes
                                                            <i className="fal fa-info-circle" />
                                                        </h4>
                                                    </div>
                                                    {isOpenSecond && (
                                                        <div
                                                            className="accrodion-content"
                                                            style={{
                                                                display:
                                                                    showQues === 4
                                                                        ? 'active'
                                                                        : 'active',
                                                            }}
                                                        >
                                                            <Slider ref={sliderRef} {...teamslider}>
                                                                {BasketData.map((s) => (
                                                                    <CardGroup>
                                                                        <Card
                                                                            style={{
                                                                                margin: '12px',
                                                                                border: 'none',
                                                                            }}
                                                                        >
                                                                            <Card.Img
                                                                                variant="top"
                                                                                src={s.img}
                                                                                style={{
                                                                                    height: '145px',
                                                                                    objectFit:
                                                                                        'contain',
                                                                                }}
                                                                            />
                                                                            <Card.Footer
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection:
                                                                                        'column',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    background:
                                                                                        'none',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    className="text-muted"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            '20px',
                                                                                    }}
                                                                                >
                                                                                    {s.title}
                                                                                </span>
                                                                                <Button
                                                                                    style={{
                                                                                        float: 'right',
                                                                                        fontSize:
                                                                                            '12px',
                                                                                        color: 'black',
                                                                                        background:
                                                                                            'white',
                                                                                    }}
                                                                                    type="submit"
                                                                                    onClick={() => {
                                                                                        setprofileType(
                                                                                            s.profile
                                                                                        );
                                                                                        localStorage.setItem(
                                                                                            'profileType',
                                                                                            s.profile
                                                                                        );
                                                                                        openQuestion(
                                                                                            1
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    See Details
                                                                                </Button>
                                                                            </Card.Footer>
                                                                        </Card>
                                                                    </CardGroup>
                                                                ))}
                                                            </Slider>
                                                        </div>
                                                    )}
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Dashboard;
