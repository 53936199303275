import React from 'react';
import { Helmet } from 'react-helmet';
// import aboutImg1 from '../../assets/images/about-img1.png';
import aboutImg1 from '../../assets/images/slider-img3.png';
import CounterUpCom from './CounterUpCom';

function AboutBlock({ className }) {
    return (
        <>
            <Helmet>
                <title>About Us: My Money Panda - Financial Planning & Investments</title>
                <meta
                    name="description"
                    content="Explore how My Money Panda excels in financial planning and mutual fund investments. Learn about our journey, values, and commitment to client success."
                />
                <meta
                    name="keywords"
                    content="My Moneypanda"
                />
            </Helmet>
            <section className={`appie-about-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-content">
                                        <h2 className="title">
                                            About Us<span></span>
                                        </h2>
                                        <h4 className="sub-title">
                                            Money Simplified
                                        </h4>
                                        {/* <p>
                                            My Money Panda aims to help individuals navigate the
                                            complex world of savings,taxation, and investments.
                                            Whether you're just starting your financial journey or
                                            looking to take your wealth management to the next
                                            level, My Money Panda provides the knowledge and tools
                                            you need to succeed.
                                        </p> */}
                                        <p>My Money Panda is your go-to wealth management platform
                                            which provides a unique and simplified investing experience.
                                            Whether you're just starting your journey or are looking to
                                            take your wealth management to the next level, My Money Panda
                                            is your personal guide to attain your financial success and freedom.
                                            Let’s learn the relevant part, ignore the myths and inculcate
                                            habits that make money work for you, not the other way round!
                                        </p>
                                        <p>Let's explore the world of finance together!</p>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="appie-single-counter1 mt-10 item-2">
                                                <div className="counter-content1">
                                                    <div className="icon">
                                                        <i className="fal fa-3x fa-user-tie" />
                                                    </div>
                                                    <h3 className="title">
                                                        <span className="counter-item">
                                                            <CounterUpCom endValue={1000} sectionSelect="counter" />
                                                        </span>
                                                        +
                                                    </h3>
                                                    <p style={{ fontWeight: 600 }}>Active Users</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="appie-single-counter1 mt-10 item-4">
                                                <div className="counter-content1">
                                                    <div className="icon">
                                                        <i className="fal fa-3x fa-rupee-sign" />
                                                    </div>
                                                    <h3 className="title">
                                                        <span className="counter-item">
                                                            <CounterUpCom endValue={370} sectionSelect="counter" />
                                                        </span>
                                                        +&nbsp;crs.
                                                    </h3>
                                                    <p style={{ fontWeight: 600 }}>AUM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='about-content mt-30'>
                                        <p>Trusted by Top Professionals, CXOs and Business Promoters</p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-thumb">
                                        <img src={aboutImg1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="appie-counter-area d-none" id="counter">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <div className="appie-single-counter mt-30 item-2">
                                            <div className="counter-content1">
                                                <div className="icon">
                                                    <i className="fal fa-3x fa-user-tie" />
                                                </div>
                                                <h3 className="title">
                                                    <span className="counter-item">
                                                        <CounterUpCom endValue={200} sectionSelect="counter" />
                                                    </span>
                                                    +
                                                </h3>
                                                <p style={{ fontWeight: 600 }}>Expert Instructors</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="appie-single-counter mt-30 item-4">
                                            <div className="counter-content1">
                                                <div className="icon">
                                                    <i className="fal fa-3x fa-badge-check" />
                                                </div>
                                                <h3 className="title">
                                                    <span className="counter-item">
                                                        <CounterUpCom endValue={6} sectionSelect="counter" />
                                                    </span>
                                                    k+
                                                </h3>
                                                <p>Certified Courses</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutBlock;
