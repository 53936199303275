import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import Parser from 'html-react-parser';
import axios from 'axios';
import { BASEAPIURL } from '../../config';

function ContactBlock({ className }) {
    // const [styleBTN, setstyleBTN] = useState({});
    const [styleMSG, setstyleMSG] = useState({
        color: '#cc0000',
        padding: '5px',
    });
    const [message, setMessage] = useState();
    const [user, SetUser] = useState({
        fname: '',
        // lname: '',
        email: '',
        phone: '',
        message: '',
    });
    let name;
    let value;
    const HandleForm = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;
        SetUser({ ...user, [name]: value });
    };

    const SendData = async (e) => {
        e.preventDefault();
        setstyleMSG({ color: '#cc0000', padding: '5px' });
        try {
            const utmSource = localStorage.getItem('utm_source');
            const leadSource = `ContactForm${utmSource === `null` ? '' : `-${utmSource}`}`;
            const crmPostData = {
                data: [
                    {
                        Lead_Source: leadSource,
                        Last_Name: user.fname,
                        Email: user.email,
                        Phone: user.phone,
                        Description: user.message,
                        Company_Name: 'My Money Panda',
                    },
                ],
            };
            const res = await fetch(`${BASEAPIURL}/enquiry/send-contact-us`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user),
            });
            const zohoCRMAxiosConfig = {
                url: 'app/crm/create-crm-lead',
                method: 'post',
                baseURL: BASEAPIURL,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: crmPostData,
            };
            await axios(zohoCRMAxiosConfig);
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.status === 1) {
                    setstyleMSG({ color: 'green', padding: '5px' });
                    // setstyleBTN({ display: 'none' });
                    //   setTimeout(() => {
                    //     window.location.href = `${BASEWEBURL}/#/manage-users`
                    //     setShowAddData(false)
                    //     FilterDataList()
                    //   }, 786)
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setMessage(err);
        }
    };
    // const SendData = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const res = await fetch(`${BASEAPIURL}/enquiry/send-contact-us`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(user),
    //         });
    //         setstyleMSG({ display: 'block' });
    //         setstyleBTN({ display: 'none' });
    //         SetUser({
    //             fname: '',
    //             // lname: '',
    //             email: '',
    //             phone: '',
    //             subject: '',
    //             message: '',
    //         });
    //         const resJson = await res.json();
    //         if (resJson.status === 1) {
    //             setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
    //         } else {
    //             setMessage(Parser(`<div class+"alert alert-danger">${resJson.message}</div>`));
    //         }
    //         setstyleBTN({ display: '' });
    //     } catch (err) {
    //         setstyleBTN({ display: '' });
    //         setMessage(Parser(`<div class="alert alert-danger">${err}</div>`));
    //     }
    // };
    return (
        <>
            <Helmet>
                <title>Contact My Money Panda: Get Expert Financial Advice Now</title>
                <meta
                    name="description"
                    content="Reach out to My Money Panda for personalized financial guidance. Contact our experts for investment, savings, and financial planning advice today!"
                />
                <meta name="keywords" content="My Moneypanda" />
            </Helmet>
            <section className={`appie-contact-area ${className || ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title mb-3 color-blue">Contact</h3>
                                <span className="m-0">Feel free to get in touch with us</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="infoBox">
                                <div>
                                    <div className="icon">
                                        <i className="fal fa-phone" />
                                    </div>
                                    <h5>+91-7710087639</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="infoBox">
                                <div>
                                    <div className="icon">
                                        <i className="fal fa-envelope" />
                                    </div>
                                    <h5>
                                        <a href="mailto:hello@mymoneypanda.in">
                                            hello@mymoneypanda.in
                                        </a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="infoBox">
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                href="https://www.facebook.com/profile.php?id=100094190271471&mibextid=D4KYlr"
                                                target="_BLANK"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/company/my-money-panda/"
                                                target="_BLANK"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                href="https://instagram.com/mymoneypanda?igshid=MzRlODBiNWFlZA=="
                                                target="_blank"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li> */}
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                href="https://www.youtube.com/@mymoneypanda"
                                                target="_blank"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ContactForm  */}

            <section className="appie-about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content pr0">
                                <h2 className="title text-center">
                                    <span className="mb-40">Send Us a Message</span>
                                </h2>
                                <div className="message-box">
                                    <div className="about-form">
                                        <form
                                            method="POST"
                                            className="row"
                                            onSubmit={(e) => {
                                                SendData(e);
                                            }}
                                        >
                                            <div className="col-lg-6">
                                                <div className="form-box">
                                                    <input
                                                        type="text"
                                                        name="fname"
                                                        value={user.fname}
                                                        required
                                                        placeholder="Enter Your Name"
                                                        onChange={HandleForm}
                                                    />
                                                    <label>Enter Your name</label>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="form-box">
                                                    <input
                                                        type="text"
                                                        name="lname"
                                                        value={user.lname}
                                                        required
                                                        placeholder="Enter Your Last Name"
                                                        onChange={HandleForm}
                                                    />
                                                    <label>Last Name</label>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6">
                                                <div className="form-box">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        required
                                                        value={user.email}
                                                        placeholder="Enter Your Email Id"
                                                        onChange={HandleForm}
                                                    />
                                                    <label>Email</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-box">
                                                    <input
                                                        required
                                                        className="phonenumber"
                                                        type="number"
                                                        name="phone"
                                                        placeholder="Enter Your Mobile number"
                                                        value={user.phone}
                                                        onChange={HandleForm}
                                                    />
                                                    <label>Mobile</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-box">
                                                    <textarea
                                                        name="message"
                                                        value={user.message}
                                                        onChange={HandleForm}
                                                        required
                                                        placeholder="Type Your Message"
                                                    ></textarea>
                                                    <label>Message</label>
                                                </div>
                                                <div
                                                    className="col-md-12"
                                                    style={{ clear: 'both' }}
                                                >
                                                    <div style={styleMSG}>{message}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <input
                                                    type="submit"
                                                    name="submit"
                                                    required
                                                    value="Send Message"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactBlock;
